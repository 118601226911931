export const NotesData = [
    {
        subject: "Mathematics",
        topic: "Additional Mathematics",
        duration: "6min",
        free: true
    },
    {
        subject: "Mathematics",
        topic: "Basic Calculus",
        duration: "10min",
        free: false
    },
    {
        subject: "Mathematics",
        topic: "Algebra",
        duration: "12min",
        free: false
    },
    {
        subject: "Chemistry",
        topic: "Chemistry 1",
        duration: "6min",
        free: false
    },
    {
        subject: "Chemistry",
        topic: "Chemistry 2",
        duration: "6min",
        free: false
    },
    {
        subject: "Chemistry",
        topic: "Chemistry 3",
        duration: "6min",
        free: false
    },
    {
        subject: "Biology",
        topic: "Biology 1",
        duration: "6min",
        free: false
    },
    {
        subject: "Biology",
        topic: "Biology 2",
        duration: "6min",
        free: false
    },
    {
        subject: "Biology",
        topic: "Biology 3",
        duration: "6min",
        free: false
    },
    {
        subject: "English",
        topic: "English 1",
        duration: "6min",
        free: false
    },
    {
        subject: "English",
        topic: "English 2",
        duration: "6min",
        free: false
    },
    {
        subject: "English",
        topic: "English 3",
        duration: "6min",
        free: false
    },
    {
        subject: "Physics",
        topic: "Physics 1",
        duration: "6min",
        free: false
    },
    {
        subject: "Physics",
        topic: "Physics 2",
        duration: "6min",
        free: false
    },
    {
        subject: "Physics",
        topic: "Physics 3",
        duration: "6min",
        free: false
    },
]