import React, { useState } from "react";
import "./Panel.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Notifications from "@components/Notifications/Notifications";
import { useNavigate } from "react-router-dom";

const Panel = () => {
  const mobile = window.innerWidth <= 900;
  const [showPanel, setShowPanel] = useState(false);
  const [showMenu, setShowMenu] = useState(true);

  const handleShowPanel = () => {
    setShowPanel(!showPanel);
  };

  const navigate = useNavigate();

  const handleTestClick = () => {
    navigate('/user/tests')
  }

  const handleShowMenu = () => {
    setShowMenu(!showMenu);
    if (showMenu === true) {
      document.getElementById("sidebar").style.display = "flex";
      document.getElementById("overlay").style.display = "block";
    } else {
      document.getElementById("overlay").style.display = "none";
      document.getElementById("sidebar").style.display = "none";
    }
  };

  return (
    <>
      {mobile === true ? (
        <div className="panel panel-icons">
          <div
            className={showMenu === false ? "ellipsis show-right" : "ellipsis"}
            onClick={handleShowMenu}
          >
            {showMenu === true ? (
              <FontAwesomeIcon icon={faEllipsisVertical} />
            ) : (
              <FontAwesomeIcon icon={faXmark} />
            )}
          </div>

          {showMenu === true ? (
            <Notifications />
          ) : null}

          {showMenu === true ? (
            <div className="panel-icon" onClick={handleShowPanel}>
              {showPanel === false ? (
                <FontAwesomeIcon icon={faBars} />
              ) : (
                <FontAwesomeIcon icon={faXmark} />
              )}
            </div>
          ) : null}
        </div>
      ) : null}

      {mobile === false || showPanel === true ? (
        <div className="panel panel-content">
          <div className="panel-left">
            <div className="subscribe">Get Subscription</div>
            <span className="divider"></span>
            <div className="cur-plan">
              <div>Current Plan</div>
              <div style={{color: 'black'}}>Free</div>
            </div>
            <span className="divider"></span>
            <a href="/blogs" className=" cta-d blog-link">
              Blogs
            </a>
          </div>

          <div className="panel-right">
            <span onClick={handleTestClick} className="cta-d test-link">Take Test</span>
            <span className="divider"></span>
            {mobile === true ? null : (
              <Notifications />
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Panel;
