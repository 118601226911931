import React from "react";

const Sidebar = ({ subjects, selected, setSelected }) => {
  return (
    <div className="sidebar" id="sidebar">
      <div className="sticky">
        <div className="menu">
          {subjects.map((item, idx) => (
            <div
            className={selected === idx ? "menu-item jst-menu-item active" : "menu-item jst-menu-item"}
              key={idx}
              style={{textTransform:'capitalize'}}
              onClick={() => setSelected(idx)}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
