import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import profileImg from "@assets/profile-image.png";
import { getAllBlogs } from "../../../../../db";
import Spinner from "../../../../../components/Spinner/Spinner";

const Sidebar = ({ tags, id }) => {
  const [loading, setLoading] = useState(false);
  const [blogsData, setBlogData] = useState([]);

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      const data = await getAllBlogs();
      setBlogData(data);
      setLoading(false);
    };

    getData();
    filterBlogs();
  }, []);

  // Filter related blogs based on tags
  const filterBlogs = () => {
    let otherBlogs = blogsData.filter((blog) => blog.id !== id);
    let relatedBlog = otherBlogs.filter((blog) =>
      blog.tags.map((tag) => tags.map((t) => tag === t))
    ); // Get related blogs
    let l = relatedBlog.length;
    setBlogData(relatedBlog.slice(l - 4, l));
  };

  return (
    <div className="b-sidebar">
      <div className="b-author">
        <img src={profileImg} alt="" />
        <h2>Akano Phillip</h2>
        <p>Author</p>
      </div>
      <div className="related-blogs">
        <h3>You might also like...</h3>
        {loading ? (
          <Spinner />
        ) : (
          blogsData &&
          blogsData.map((blog, i) => (
            <div className="r-blog-item" key={i}>
              <h3>{blog.title}</h3>
              <div className="blogs-info">
                <p>6 mins read</p>
                <a href={`/blog/read/${blog.id}`}>
                  <button className="btn">read more -&gt;</button>
                </a>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Sidebar;
