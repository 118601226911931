import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { UserAuth } from "../../contexts/AuthContext";

const ResetPassForm = ({ showNotification }) => {
  const iconStyle = { color: "rgba(0,0,0,0.5)" };
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { reset } = UserAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    reset(email) // function gotten from userAuth for sending email verify token to users
      .then((res) => {
        setLoading(false);
        showNotification("Reset Email Sent", "success"); // send message to react toastifier
      })
      .catch((err) => {
        setLoading(false);
        showNotification("Email Not Sent", "error");
      });
  };

  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <div
        className="form-error"
        style={error ? { display: "block" } : { display: "none" }}
      >
        {error}
      </div>

      <div className="form-item">
        <h3>Enter your email:</h3>
      </div>
      <div className="form-item">
        <FontAwesomeIcon icon={regular("user")} style={iconStyle} />
        <input
          type="email"
          name="reset-email"
          id="reset-email"
          placeholder="Email:"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <button
        type="submit"
        className="login-btn"
        style={{
          alignSelf: "center",
          width: "fit-content",
          borderRadius: "10px",
        }}
      >
        {loading ? (
          <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
        ) : (
          "Get Email"
        )}
      </button>
    </form>
  );
};

export default ResetPassForm;
