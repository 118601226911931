import "./Dashboard.css";
import React from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Panel from "../components/Panel/Panel";
import { useState, useEffect } from "react";
import Spinner from "@components/Spinner/Spinner";
import Footer from "@components/Footer/Footer";
import { UserAuth } from "../../../contexts/AuthContext";
import { adminData } from "../../../data/adminData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import valueFormatter from "../../../hooks/valueFormatter";
import { useLocalStorage } from "@hooks/useLocalStorage";


const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const { user } = UserAuth();
  const [name, setName] = useLocalStorage("name", "");
  
  useEffect(() => {
    setName(user.displayName);
  }, [user.displayName]);

  return (
    <div className="page-wrapper">
      <Sidebar id={0} />
      <div className="container">
        <Panel />
        {loading ? (
          <Spinner />
        ) : (
          user && (
            <div className="page-content">
              <div className="overlay" id="overlay"></div>
              <div className="user-section admin-section">
                <div className="admin-greeting">
                  <div className="greeting">
                    <span>Hi,</span>
                    <span>{name ? name.split(" ")[0] : name}👋</span>
                  </div>
                </div>
                {adminData.map((data, i) => (
                  <div className="admin-card" key={i}>
                    <div
                      className="card-icon"
                      style={{ background: data.gradient, color: "white" }}
                    >
                      <FontAwesomeIcon icon={data.icon} />
                    </div>

                    <div className="card-data">
                      <div className="data-name">{data.name}</div>
                      <div className="data-value">
                        {valueFormatter(data.value)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <Footer />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Dashboard;
