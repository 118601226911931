import React from "react";
import TextIcon from "@assets/text.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faXmark, faPen, faImage } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import Editor from "../components/Editor/Editor";
import { toast } from "react-toastify";
import { setBlog, uploadImage, updateBlog } from "../../../db";
import FileBase from "react-file-base64";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const CreateBlog = ({ edit }) => {
  const [showSubtitle, setShowSubtitle] = useState(false);
  const [open, setOpen] = useState(false);
  const [tag, setTag] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const initialData = {
    imageUrl: "",
    title: "",
    subtitle: "",
    details: "",
    tags: [],
  };
  const [blogData, setBlogData] = useState({ ...initialData });
  const [addImage, setAddImage] = useState(false);

  useEffect(() => {
    document.title = edit ? "Edit Blog" : "Create Blog";
    if (edit) {
      const data = location.state.data;
      setBlogData({ ...data });
    }
  }, []);

  const setText = (details) => {
    setBlogData({ ...blogData, details });
  };

  const setImage = async (file) => {
    const { success, url, error } = await uploadImage(file);
    if (success) {
      setBlogData({ ...blogData, imageUrl: url });
      showNotification(success, "success");
    } else {
      showNotification(error, "error");
    }
  };

  const showNotification = (notification, type) => {
    toast(notification, {
      theme: "light",
      type: type,
    });
  };

  const style = open ? { display: "flex" } : { display: "none" };

  const handlePublish = async () => {
    setOpen(false);
    setLoading(true);
    let validated = validateData();
    try {
      if (validated) {
        // Update blog if edit is true else create blog
        let notification;
        if (edit) {
          notification = await updateBlog(blogData.id, blogData);
        } else {
          notification = await setBlog(blogData);
        }
        setLoading(false);
        setBlogData(initialData);
        showNotification(notification, "success");
      } else {
        setLoading(false);
        showNotification("Incomplete Data", "error");
      }
    } catch (error) {
      setLoading(false);
      showNotification(error.message, "error");
    }
  };

  const validateData = () => {
    if (
      blogData.details &&
      blogData.imageUrl &&
      blogData.title &&
      blogData.tags
    ) {
      return true;
    } else {
      return false;
    }
  };

  // functions for adding tags and removing tags
  const handleKeyDown = (e) => {
    if (e.code === "Space") {
      if (blogData.tags.length < 3) {
        if (tag !== "") {
          if (blogData.tags.includes(tag)) {
          } else {
            setBlogData({ ...blogData, tags: [...blogData.tags, tag] });
            setTag("");
          }
        }
      } else {
        showNotification("Maximum of 3 notifications", "error");
      }
    }
  };

  const removeTag = (tagName) => {
    let tags = blogData.tags.filter((tag) => tag !== tagName);
    setBlogData({ ...blogData, tags: tags });
  };

  return (
    <div className="create-note">
      <div
        className="overlay"
        style={style}
        onClick={() => setOpen(false)}
      ></div>

      <div className="heading">
        <div className="h-left">
          <FontAwesomeIcon icon={faPen} />
          <h2 style={{ color: "black" }}>
            {edit ? "Edit Blog" : "Create Blog"}
          </h2>
        </div>
        <div className="h-right">
          <button>Save Draft</button>
          <button onClick={() => setOpen(true)}>
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
            ) : (
              "Publish"
            )}
          </button>
        </div>
      </div>

      <div className="note-forms">
        <div className="modal-container" style={style}>
          <div className="modal">
            <div className="modal-message">
              Do you want to publish this Blog?
            </div>

            <button type="submit" className="btn" onClick={handlePublish}>
              Publish
            </button>
          </div>
        </div>

        {!showSubtitle ? (
          <div className="add-subtitle">
            <button onClick={() => setShowSubtitle(true)}>
              <img src={TextIcon} alt="icon" />
              <span>Add Subtitle</span>
            </button>
          </div>
        ) : null}

        <div className="add-image">
          <div>
            <button className="btn" onClick={() => setAddImage(!addImage)}>
              <FontAwesomeIcon icon={faImage} />
              <span>{addImage ? "Close" : "Add Image"}</span>
            </button>
            <br />
            {addImage && (
              <FileBase
                type="file"
                multiple={false}
                onDone={(file) => setImage(file)}
              />
            )}
          </div>
        </div>

        <div className="title-container">
          <textarea
            placeholder="Article Title ..."
            className="material-title"
            style={{ height: "84px !important" }}
            value={blogData.title}
            onChange={(e) =>
              setBlogData({ ...blogData, title: e.target.value })
            }
          ></textarea>
        </div>

        {showSubtitle ? (
          <div className="subtitle-container">
            <textarea
              placeholder="Article Subtitle ..."
              className="material-subtitle"
              style={{ height: "66px !important" }}
              value={blogData.subtitle}
              onChange={(e) =>
                setBlogData({ ...blogData, subtitle: e.target.value })
              }
            ></textarea>

            <button onClick={() => setShowSubtitle(false)}>
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
        ) : null}

        <Editor text={blogData.details} textHandler={setText} />

        <div className="add-tag">
          <h2 style={{ color: "black" }}>Add Tags</h2>
          <textarea
            placeholder="Separate with spaces"
            className="material-subtitle"
            style={{ border: "1px solid grey" }}
            value={tag}
            onChange={(e) => setTag(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className="tags-container">
          {blogData.tags.map((tag, i) => (
            <div className="tag-item" key={i}>
              <span>{tag}</span>{" "}
              <button onClick={() => removeTag(tag)}>
                <FontAwesomeIcon icon={faXmark} />
              </button>{" "}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CreateBlog;
