import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  updateProfile, 
  sendPasswordResetEmail,
} from "firebase/auth";

import { auth } from "../firebase";
import { createUserData, getUserData } from "../db";



const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});

  const createUser = async (email, password, firstName, lastName) => {
    try {
      return createUserWithEmailAndPassword(auth, email, password)
      .then((res) =>{
        updateProfile(res.user, {displayName: `${firstName} ${lastName}`})
        let name = `${firstName} ${lastName}`
        createUserData(res.user.uid, name, email)
      })
    } catch (err) {
      console.log(err.message)
    }
  };

  const logout = () => {
    signOut(auth);
    localStorage.setItem("user-id", "");
  };

  const reset = async (email) => {
    return await sendPasswordResetEmail(auth, email)
  }

  const login = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
    });

    return () => {
      unsubscribe();
    };
  });

  return (
    <UserContext.Provider value={{ createUser, user, reset, logout, login }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
