import React from "react";
import { useNavigate } from "react-router-dom";
import "./About.css";

const About = () => {
  const navigate = useNavigate();
  return (
    <div className="about-wrapper">
      <button className="btn" onClick={() => navigate("/")}>
        {" "}
        {"<-"} Back to Home
      </button>
      <div className="about-container">
        <div className="about-hero">
          <h1>
            About <span className="stroke-text hero-stroke-text">Us</span>
          </h1>
        </div>
        <div className="card about-card">
          <p>
            On the 3rd of January, 2021, the Company's name Scholars Breed
            Academy Limited was given by the inspiration of God to the Founder.
          </p>
          <p>
            Initially, there was a plan to start up an online tutorial in order
            to help students learn effectively, conveniently and efficiently
            from the comfort of their homes. We wanted to get across to as many
            students as possible.
          </p>
          Here in Scholars Breed Academy Limited, we strongly believe that no
          examination will be difficult for our students to ace excellently as
          long as they're groomed properly with the right tutelage. More so, we
          believe that anyone and everyone can be a scholar in any proposed
          field of study with proper guidance and mentoring. It is for this
          reason the company was named SCHOLARS BREED ACADEMY LIMITED.
          <p>
            Scholars Breed Academy Limited(founded since 3rd March, 2021), a
            sole proprietorship is an online tutorial with global recognition
            and reputation. We deal in WASSCE, UTME and post-UTME in order to
            help admission seekers into the their preferred courses and
            institutions.
          </p>
          <p>
            Over the years, since the inception of this company, with much
            critical acclaim, our philosophy has remained true to its roots by
            providing a truly distinct tutorial that provides the best
            educational service which in the long run did help and is still
            helping students in actualizing dreams through scholastic approach.
          </p>
        </div>
        <h2>
          <span className="stroke-text hero-stroke-text">Our</span> Mission
        </h2>
        <div className="card about-card">
          <p>
            Our mission is to provide explicit, concise, simplified, detailed
            and easy-to-learn tutorial for students to get the best, beat the
            best and be the best, setting the pace among the best of their
            equals. We also strive to uphold the priceless value and excellence
            of education by providing inclusive, competent and professional
            supports to students to aid their success in their examinations.
          </p>
        </div>
        <h2>
          Our <span className="stroke-text hero-stroke-text">Vision</span>
        </h2>
        <div className="card about-card">
          <ul>
            <li>
              To help students identify their scholastic abilities regardless of
              their foundation.
            </li>
            <li>
              To help students identify their weak subject areas, perfect them,
              identify their strengths and be better at them.
            </li>
            <li>
              To produce students who will be the best and beat the best in
              their various fields of study.
            </li>
            <li>
              To ensure that our students are properly guided on their choice of
              courses and institutions and in the long run help them achieve
              their dreams and aspirations.
            </li>
            <li>
              To make learning not just a reality but fun and an avenue to
              achieving greatness bringing to light the importance/worth and
              love of education.
            </li>
          </ul>
        </div>

        <h2>
          <span className="stroke-text hero-stroke-text">Our</span> Objective
        </h2>
        <div className="card about-card">
          <p>
            What we plan on achieving within a short period of time is to
            increase our staff base and extensively expand our horizon in order
            to provide top-notch educational services for all levels which we
            believe in the long run will produce highly intellectual students
            who would perform excellently well in their exams, scholars who wil
            be unparalleled in their respective fields of endeavour. God helping
            us, our long term goal is to open offices in the country's capital
            Abuja, Beijing and possibly the UK.
          </p>
        </div>
        <h2>
          Our Key <span className="stroke-text hero-stroke-text">Skills</span>{" "}
          and <span className="stroke-text hero-stroke-text">Competences</span>
        </h2>
        <div className="card about-card">
          <ul>
            <li>Up-to-date knowledge of the admission system in Nigeria.</li>
            <li>Intelligence on competitor activity.</li>
            <li>Mastery in impacting and transforming students.</li>
            <li>
              Tips to answering exam questions in the shortest time possible (
              For instance, answering a Maths question in less than 10 seconds).
            </li>
            <li>
              Proven tips to finishing and effectively understanding the
              syllabus within a short period of time.
            </li>
            <li>
              Organizing multiple tasks alongside an effective work schedule.
            </li>
            <li>
              Applying flexibility and open-mindedness to enable the students
              adapt to the change they need to grasp what they've been taught
              and apply them appropriately which in the long run will help them
              ace their exams successfully.
            </li>
            <li>
              Maintaining Team work for the overall success and development of
              the company.
            </li>
            <li>Conducting responsiveness and responsibilities.</li>
            <li>
              Giving attention to details and maintaining the quality and
              standards of the company.
            </li>
            <li>Training staff members for optimum productivity.</li>
          </ul>
        </div>
        <h2>
          <span className="stroke-text hero-stroke-text">Our</span> Values
        </h2>
        <div className="card about-card">
          <ul>
            <li>Honesty</li>
            <li>Integrity</li>
            <li>Objectivity</li>
            <li>Open Communication</li>
            <li>Transparency</li>
            <li>Professional Skills</li>
            <li>Due Care Confidentiality</li>
            <li>Professional Behaviour Inclusiveness</li>
            <li>Impartiality</li>
            <li>Respect</li>
            <li>Consideration</li>
            <li>Empathy</li>
            <li>Inspiration</li>
          </ul>
        </div>

        <h3>We are committed</h3>
        <h3>We do not believe in impossibility, whatever we target, we get</h3>
        <h3>We are hardworking</h3>
        <h3>We fulfil promises</h3>
        <h3>We do not give up</h3>
      </div>
    </div>
  );
};

export default About;
