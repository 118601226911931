export const LeaderboardData = [
    {
        name: "Kehinde Adejumobi",
        score: 89
    },
    {
        name: "Kehinde Adejumobi",
        score: 83
    },
    {
        name: "Kehinde Adejumobi",
        score: 70
    },
    {
        name: "Kehinde Adejumobi",
        score: 83
    },
    {
        name: "Kehinde Adejumobi",
        score: 44
    },
    {
        name: "Kehinde Adejumobi",
        score: 90
    },

]