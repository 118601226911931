import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import graduation from "@assets/graduation.jpg";
import Spinner from "../../../components/Spinner/Spinner";
import ResetPassForm from "../../../Forms/ResetPassForm/ResetPassForm";
import { ToastContainer, toast } from "react-toastify";


const ResetPassword = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const showNotification = (notification, type) => {
    toast(notification, {
      theme: "light",
      type: type,
    });
  };

  const style = {
    backgroundColor: "white",
    zIndex: 2,
  };

  return (
    <div className="loading-container">
      {
        loading ?
         <Spinner />
        :

        <div className="login-container">
            <ToastContainer />
          <div className="title">
            <Link to="/" >
              <h1>Scholars Breed</h1>
            </Link>
    
            <h2>Actualizing Dreams With The Scholastic Approach</h2>
          </div>
    
          <div className="login">
            <div className="options">
              <span style={style}>Reset</span>
              <Link className="signup-link" to="/auth/login">
                <span>Login</span>
              </Link>
            </div>
            <ResetPassForm showNotification={showNotification} />
          </div>
    
          <img src={graduation} alt="" />
        </div>
      }
      </div>
  );
};

export default ResetPassword;
