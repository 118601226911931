import blogImage from "../assets/blog-image.jpg";

export const blogData = [
  {
    image: blogImage,
    title: "Blog 1",
    details:
      "Velit culpa esse nostrud consectetur ut proident qui minim ex amet pariatur minim. Proident officia consequat est labore culpa sunt exercitation incididunt excepteur aliquip culpa. Proident et ipsum veniam id aute ipsum ullamco id ad. Elit reprehenderit excepteur sit excepteur. Nostrud irure esse consequat in occaecat fugiat sit do est cillum. Dolor cillum consectetur voluptate exercitation exercitation. Adipisicing mollit ullamco laborum cupidatat. \n Nulla velit veniam occaecat nulla esse id ut culpa Lorem eu. Cillum consequat amet sunt qui commodo adipisicing ut laborum cupidatat mollit excepteur dolore. Amet non commodo elit pariatur tempor Lorem proident eiusmod id. \n Cupidatat ut nostrud consequat magna irure veniam laboris veniam dolore cupidatat laboris duis fugiat irure. Quis adipisicing anim esse deserunt tempor non sint. Est laborum ullamco officia in. Esse deserunt qui irure non qui elit nisi esse est esse sit sunt occaecat duis. Ex laborum nisi nisi nisi consectetur in amet id est aute id fugiat commodo in. Lorem adipisicing tempor cupidatat ipsum ut voluptate proident sunt nostrud.",
    tags: ["tag 1", "tag 2", "tag 3"],
  },
  {
    image: blogImage,
    title: "Blog 2",
    details:
      "Velit culpa esse nostrud consectetur ut proident qui minim ex amet pariatur minim. Proident officia consequat est labore culpa sunt exercitation incididunt excepteur aliquip culpa. Proident et ipsum veniam id aute ipsum ullamco id ad. Elit reprehenderit excepteur sit excepteur. Nostrud irure esse consequat in occaecat fugiat sit do est cillum. Dolor cillum consectetur voluptate exercitation exercitation. Adipisicing mollit ullamco laborum cupidatat. \n Nulla velit veniam occaecat nulla esse id ut culpa Lorem eu. Cillum consequat amet sunt qui commodo adipisicing ut laborum cupidatat mollit excepteur dolore. Amet non commodo elit pariatur tempor Lorem proident eiusmod id. \n Cupidatat ut nostrud consequat magna irure veniam laboris veniam dolore cupidatat laboris duis fugiat irure. Quis adipisicing anim esse deserunt tempor non sint. Est laborum ullamco officia in. Esse deserunt qui irure non qui elit nisi esse est esse sit sunt occaecat duis. Ex laborum nisi nisi nisi consectetur in amet id est aute id fugiat commodo in. Lorem adipisicing tempor cupidatat ipsum ut voluptate proident sunt nostrud.",
    tags: ["tag 1", "tag 2", "tag 3"],
  },
  {
    image: blogImage,
    title: "Blog 3",
    details:
      "Velit culpa esse nostrud consectetur ut proident qui minim ex amet pariatur minim. Proident officia consequat est labore culpa sunt exercitation incididunt excepteur aliquip culpa. Proident et ipsum veniam id aute ipsum ullamco id ad. Elit reprehenderit excepteur sit excepteur. Nostrud irure esse consequat in occaecat fugiat sit do est cillum. Dolor cillum consectetur voluptate exercitation exercitation. Adipisicing mollit ullamco laborum cupidatat. \n Nulla velit veniam occaecat nulla esse id ut culpa Lorem eu. Cillum consequat amet sunt qui commodo adipisicing ut laborum cupidatat mollit excepteur dolore. Amet non commodo elit pariatur tempor Lorem proident eiusmod id. \n Cupidatat ut nostrud consequat magna irure veniam laboris veniam dolore cupidatat laboris duis fugiat irure. Quis adipisicing anim esse deserunt tempor non sint. Est laborum ullamco officia in. Esse deserunt qui irure non qui elit nisi esse est esse sit sunt occaecat duis. Ex laborum nisi nisi nisi consectetur in amet id est aute id fugiat commodo in. Lorem adipisicing tempor cupidatat ipsum ut voluptate proident sunt nostrud.",
    tags: ["tag 1", "tag 2", "tag 3"],
  },
  {
    image: blogImage,
    title: "Blog 4",
    details:
      "Velit culpa esse nostrud consectetur ut proident qui minim ex amet pariatur minim. Proident officia consequat est labore culpa sunt exercitation incididunt excepteur aliquip culpa. Proident et ipsum veniam id aute ipsum ullamco id ad. Elit reprehenderit excepteur sit excepteur. Nostrud irure esse consequat in occaecat fugiat sit do est cillum. Dolor cillum consectetur voluptate exercitation exercitation. Adipisicing mollit ullamco laborum cupidatat. \n Nulla velit veniam occaecat nulla esse id ut culpa Lorem eu. Cillum consequat amet sunt qui commodo adipisicing ut laborum cupidatat mollit excepteur dolore. Amet non commodo elit pariatur tempor Lorem proident eiusmod id. \n Cupidatat ut nostrud consequat magna irure veniam laboris veniam dolore cupidatat laboris duis fugiat irure. Quis adipisicing anim esse deserunt tempor non sint. Est laborum ullamco officia in. Esse deserunt qui irure non qui elit nisi esse est esse sit sunt occaecat duis. Ex laborum nisi nisi nisi consectetur in amet id est aute id fugiat commodo in. Lorem adipisicing tempor cupidatat ipsum ut voluptate proident sunt nostrud.",
    tags: ["tag 1", "tag 2", "tag 3"],
  },
  {
    image: blogImage,
    title: "Blog 5",
    details:
      "Velit culpa esse nostrud consectetur ut proident qui minim ex amet pariatur minim. Proident officia consequat est labore culpa sunt exercitation incididunt excepteur aliquip culpa. Proident et ipsum veniam id aute ipsum ullamco id ad. Elit reprehenderit excepteur sit excepteur. Nostrud irure esse consequat in occaecat fugiat sit do est cillum. Dolor cillum consectetur voluptate exercitation exercitation. Adipisicing mollit ullamco laborum cupidatat. \n Nulla velit veniam occaecat nulla esse id ut culpa Lorem eu. Cillum consequat amet sunt qui commodo adipisicing ut laborum cupidatat mollit excepteur dolore. Amet non commodo elit pariatur tempor Lorem proident eiusmod id. \n Cupidatat ut nostrud consequat magna irure veniam laboris veniam dolore cupidatat laboris duis fugiat irure. Quis adipisicing anim esse deserunt tempor non sint. Est laborum ullamco officia in. Esse deserunt qui irure non qui elit nisi esse est esse sit sunt occaecat duis. Ex laborum nisi nisi nisi consectetur in amet id est aute id fugiat commodo in. Lorem adipisicing tempor cupidatat ipsum ut voluptate proident sunt nostrud.",
    tags: ["tag 1", "tag 2", "tag 3"],
  },
  {
    image: blogImage,
    title: "Blog 6",
    details:
      "Velit culpa esse nostrud consectetur ut proident qui minim ex amet pariatur minim. Proident officia consequat est labore culpa sunt exercitation incididunt excepteur aliquip culpa. Proident et ipsum veniam id aute ipsum ullamco id ad. Elit reprehenderit excepteur sit excepteur. Nostrud irure esse consequat in occaecat fugiat sit do est cillum. Dolor cillum consectetur voluptate exercitation exercitation. Adipisicing mollit ullamco laborum cupidatat. \n Nulla velit veniam occaecat nulla esse id ut culpa Lorem eu. Cillum consequat amet sunt qui commodo adipisicing ut laborum cupidatat mollit excepteur dolore. Amet non commodo elit pariatur tempor Lorem proident eiusmod id. \n Cupidatat ut nostrud consequat magna irure veniam laboris veniam dolore cupidatat laboris duis fugiat irure. Quis adipisicing anim esse deserunt tempor non sint. Est laborum ullamco officia in. Esse deserunt qui irure non qui elit nisi esse est esse sit sunt occaecat duis. Ex laborum nisi nisi nisi consectetur in amet id est aute id fugiat commodo in. Lorem adipisicing tempor cupidatat ipsum ut voluptate proident sunt nostrud.",
    tags: ["tag 1", "tag 2", "tag 3"],
  },
];
