import React from "react";
import { useState } from "react";
import Sidebar from "./components/Sidebar";
import TestPanel from "../Test/components/TestPanel";
import Test from "../Test/Test";
import { UserAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { setScore as dbSetScore } from "../../db";

const JambTest = ({ subjects, questions, duration }) => {
  const now_in_ms = new Date().getTime();
  const targetTime = now_in_ms + duration;
  const [start, setStart] = useState(false);
  const [questions1, questions2, questions3, questions4] = questions;
  const totalLength =
    questions1.length +
    questions2.length +
    questions3.length +
    questions4.length;
  const time_to_solve_1 = duration / (totalLength * 60 * 1000);
  const [selected, setSelected] = useState(0);
  const [endTest, setEndTest] = useState(false);
  const [open, setOpen] = useState(false);
  const [answers1, setAnswers1] = useState([])
  const [answers2, setAnswers2] = useState([])
  const [answers3, setAnswers3] = useState([])
  const [answers4, setAnswers4] = useState([])

  const {user} = UserAuth();
  const navigate = useNavigate();

  let answersList = [answers1, answers2, answers3, answers4]
  let setAnswersList = [setAnswers1, setAnswers2, setAnswers3, setAnswers4]

  const submitTest = async () => {  // Function for submitting test
    // Scores
    let score1=0;
    let score2=0;
    let score3=0;
    let score4=0;

    let scores = [score1, score2, score3, score4];
    let averageScore = 0;
    if (endTest) {
      for (let i = 0; i < 4; i++) {
        answersList[i].map(answer => {
          if(questions[i][answer.id].answer === answer.value) {
            subjects[i].toLowerCase() === 'english' ? scores[i] += 5: scores[i] += 10;
          }
        })
      }

      averageScore = average(...scores);
    }

    await dbSetScore({id: user.uid, score: averageScore})

    navigate('/user/show-jamb-scores', {
      state: {
        subjects: subjects,
        scores: scores,
        average: averageScore
      }
    })
  };

  const average = (s1, s2, s3, s4) => {
    return (s1 + s2 + s3 + s4) / 4
  }

  return (
    <>
      {start ? (
        <div className="page-wrapper">
          {open && (
        <>
          <div
            className="overlay"
            style={{ display: "block" }}
            onClick={() => setOpen(false)}
          />
          <div
            className="modal-container"
            style={{ justifySelf: "center", margin: "2rem auto" }}
          >
            <div className="modal">
              <div className="modal-message">Do you want to submit?</div>
              <button
                type="submit"
                className="btn"
                onClick={() => submitTest()}
              >
                Submit
              </button>
            </div>
          </div>
        </>
      )}
          <Sidebar
            subjects={subjects}
            selected={selected}
            setSelected={setSelected}
          />
          {subjects.map((subject, i) => (
            <div
              key={i}
              className="container"
              style={selected === i ? {display: 'flex'} : {display: 'none'}}
            >
              <TestPanel
                subject={subject}
                targetTime={targetTime}
                setEndTest={setEndTest}
                mode="jamb"
              />
              <div className="page-content">
                  <Test
                    key={i}
                    setSubject={setSelected}
                    subjectId={selected}
                    mode="jamb"
                    answers={answersList[i]}
                    setAnswers={setAnswersList[i]}
                    questions={questions[i]}
                    subject={subject}
                    setEndTest={setEndTest}
                    setOpen={setOpen}
                     />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="test-intro">
          <div className="test-detail">
            <h2 className="test-intro-subject">Practice Mode</h2>
            <div className="test-instruction">
              <h4>INSTRUCTIONS</h4>
              <p>Hello!</p>
              <p>
                This test consists of {totalLength} questions. The time to solve
                one question is {time_to_solve_1}{" "}
                {time_to_solve_1 > 1 ? "minutes" : "minute"}.
              </p>
              <p>Make sure you have enough time and then start the test.</p>
              <p>Good Luck!</p>
            </div>
            <button className="btn" onClick={() => setStart(true)}>
              Start Test
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default JambTest;
