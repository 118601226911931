import React, { useEffect, useState } from "react";
import "./ViewQuestions.css";
import { useLocation } from "react-router-dom";
import { testData } from "@data/testData";
import ViewQuestion from "./ViewQuestion/ViewQuestion";
import CreateTestForm from "@forms/createTestForm/CreateTestForm";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import {getDailyQuestions} from '../../../../db'

const ViewQuestions = () => {
  const location = useLocation();
  const [questions, setQuestions] = useState([]);
  const { subject } = location.state;

  useEffect(() => {
    const getQuestions = async () => {
      const questionData = await getDailyQuestions(subject.subject)
      if (questionData) {setQuestions(questionData.questions)}
    };

    getQuestions();
  }, [subject]);

  const showNotification = (notification) => {
    toast(notification, {
      theme: "light",
      });
  }

  return (
    <div className="view-questions">
      <div className="heading">
        <div className="h-left">
          <FontAwesomeIcon icon={faPen} />
          <h2>Create Test</h2>
        </div>

        <div className="h-right">{subject.subject}</div>
      </div>
      <ToastContainer />
      <CreateTestForm subject={subject.subject} showNotification={showNotification} />
      {questions ? (
        <div className="questions-list">
          {questions.map((question, i) => (
            <ViewQuestion question={question} key={i} num={i + 1} />
          ))}
        </div>
      ) : (
        <div>No Questions Yet</div>
      )}
    </div>
  );
};

export default ViewQuestions;
