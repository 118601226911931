export const SubjectsData = [
    {
        subject:'Mathematics'
    },
    {
        subject: 'English'
    },
    {
        subject: 'Physics'
    },
    {
        subject: 'Chemistry'
    },
    {
        subject: 'Biology'
    },
    {
        subject: 'Accounting'
    },
    {
        subject: 'Lit. In English'
    },
    {
        subject: 'Economics'
    },
    {
        subject: 'Government'
    },
    {
        subject: 'CRS'
    },
    {
        subject: 'IRK'
    },

]





