import React from "react";
import "./Toolbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toolbarData } from "@data/toolbarData";
import {
  faPen,
  faEye,
  faGlobe,
  faHeading,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import toolbarFunctions from "./functions";

const Toolbar = ({
  markdown,
  previewHandler,
  writeHandler,
  active,
  markHandler,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const optionHandler = (name) => {
    toolbarFunctions({ name: name, markdown, setMarkdown: markHandler });
    setShowDropdown(false);
  };

  return (
    <div className="toolbar">
      <div className="toolbar-content">
        <div className="toolbar-left">
          <button
            className={active ? "tool-option" : "tool-option tool-active"}
            title="Write Markdown"
            onClick={writeHandler}
          >
            <FontAwesomeIcon icon={faPen} />
            <span>Write</span>
          </button>

          <button
            className={active ? "tool-option tool-active" : "tool-option"}
            title="Preview Markdown"
            onClick={previewHandler}
          >
            <FontAwesomeIcon icon={faEye} />
            <span>Preview</span>
          </button>

          <button className="tool-option" title="Editor Guide">
            <FontAwesomeIcon icon={faGlobe} />
            <span>Editor Guide</span>
          </button>
        </div>

        {active ? null : (
          <div className="toolbar-right">
            <div className="heading-container">
              <button
                className="tool-option"
                title="heading"
                onClick={() => setShowDropdown(!showDropdown)}
              >
                <FontAwesomeIcon icon={faHeading} />
              </button>
              <div
                className="h-dropdown"
                style={showDropdown ? null : { display: "none" }}
              >
                <div
                  className="h-dropdown-item"
                  onClick={() => optionHandler("heading1")}
                >
                  <span>H1</span>
                  <span>Heading 1</span>
                </div>
                <div
                  className="h-dropdown-item"
                  onClick={() => optionHandler("heading2")}
                >
                  <span>H2</span>
                  <span>Heading 2</span>
                </div>
                <div
                  className="h-dropdown-item"
                  onClick={() => optionHandler("heading3")}
                >
                  <span>H3</span>
                  <span>Heading 3</span>
                </div>
              </div>
            </div>
            {toolbarData.map((data, i) => (
              <>
              {i === 2 || i === 4 || i===7 ? <div className="divider"></div> :null}
              <button
                key={data.name}
                className="tool-option"
                title={data.name}
                onClick={() => optionHandler(data.name)}
              >
                <FontAwesomeIcon icon={data.icon} />
              </button>
              </>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Toolbar;
