import React from 'react'
import './Footer.css'
import Logo from '@assets/logo2.png'

const Footer = () => {
  return (
    <div className="footer-wrapper">
        <div className="footer-content">
          <div className='f-logo'>
            <img src={Logo} alt="" />
            <p>Actualizing Dreams With The Scholastic Approach</p>
          </div>

          <div className="links">
            <ul>
              <li>About</li>
              <li>About Us</li>
              <li>Pricing</li>
              <li>Blog</li>
              <li>Contact Us</li>
            </ul>

            <ul>
              <li>Legal</li>
              <li>Terms of Use</li>
              <li>Privacy Policy</li>
              <li>Cookie Policy</li>
            </ul>

            <ul>
              <li>Social</li>
              <li>Facebook</li>
              <li>Youtube</li>
              <li>Twitter</li>
              <li>WhatsApp</li>
              <li>LinkedIn</li>
            </ul>
          </div>
        </div>
        <div className="copyright">
          &copy; Scholars Breed, 2022
        </div>
    </div>
  )
}

export default Footer