import React from "react";
import "./Editor.css";
import { useState } from "react";
import Toolbar from "../Toolbar/Toolbar";
import Preview from "../Preview/Preview";

const Editor = ({text, textHandler}) => {
  const [preview, setPreview] = useState(false);
  const [active, setActive] = useState(0);

  const showPreview = () => {
    setPreview(true);
    setActive(1)
  };

  const showTextArea = () => {
    setPreview(false);
    setActive(0)
  };

  return (
    <div className="editor">
      <Toolbar
        markdown={text}
        previewHandler={showPreview}
        writeHandler={showTextArea}
        active={active}
        markHandler={textHandler}
      />
      {preview ? (
        <Preview markdown={text} />
      ) : (
        <div className="text-editor">
          <textarea
            placeholder="Write your Notes ..."
            id="editor"
            value={text}
            onChange={(e) => textHandler(e.target.value)}
            className="material-note"
            style={{ height: "124px !important" }}
          ></textarea>
        </div>
      )}
    </div>
  );
};

export default Editor;
