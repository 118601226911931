import {useState, useEffect} from "react"
import checkUserRole from "../../utils/checkUserRole";
import { useNavigate } from "react-router-dom";
import {useSigninCheck} from "reactfire"
import Spinner from "../../components/Spinner/Spinner";

const AdminProtectedRoute = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const { status, data: signInCheckResult } = useSigninCheck();
  const navigate = useNavigate();

  useEffect(() => {
    // navigate to login page if status is no longer loading and signInCheckResult.signedIn is false
    if (status === "loading") {
      setLoading(true);
    } else if (!signInCheckResult.signedIn) {
      // navigates to login page if user is not signed in
      navigate("/auth/login");
    } else {
      const uid = localStorage.getItem("user-id")
      // checks user role, if they're not admin, it redirects them back to dashboard
      checkUserRole(uid).then(isAdmin => {
        isAdmin? setLoading(false): navigate("/user/dashboard")
      })
    }
  }, [status]);

  if (loading) {
    return <Spinner />;
  }

  return children;

};

export default AdminProtectedRoute;
