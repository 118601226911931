import {faShop, faChartSimple, faUsers} from "@fortawesome/free-solid-svg-icons"

export const adminData = [
    {
        name: "Total Users",
        icon: faChartSimple,
        value: 10000,
        gradient: 'linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))'
    },
    {
        name: "Subscribed Users",
        icon: faUsers,
        value: 5000,
        gradient: 'linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71))'
    },
    {
        name: "Month Revenue",
        icon: faShop,
        value: 300000,
        gradient: 'linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))'
    },
]