import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore"
import {getStorage} from "firebase/storage"
// import { getAnalytics } from "firebase/analytics";

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID
// };

export const firebaseConfig = {
  apiKey: "AIzaSyBk6ilBaf-JIPw2w7OS1TGyoyyFIpJMAxw",
  authDomain: "scholars-breed-production.firebaseapp.com",
  projectId: "scholars-breed-production",
  storageBucket: "scholars-breed-production.appspot.com",
  messagingSenderId: "545403495625",
  appId: "1:545403495625:web:ea8957933e20275f529918",
  measurementId: "G-E5HZ8Z9L5Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app)


