import {
    UilEstate,
    UilUsersAlt,
    UilCalculatorAlt,
    UilBookOpen, 
} from '@iconscout/react-unicons'

export const SideBarData = [
    {
        icon: UilEstate,
        heading: "Dashboard",
        link: "/user/dashboard"
    },
    {
        icon: UilBookOpen,
        heading: "Notes",
        link: "/user/notes"
    },
    {
        icon: UilCalculatorAlt,
        heading: "Tests",
        link: "/user/tests"
    },
    {
        icon: UilUsersAlt,
        heading: "Live Classes",
        link: "/#",
    }
]