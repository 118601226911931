import React from "react";
import "./FreeTest.css";
import axios from "axios";
import constants from "@config/constants";
import { useState, useEffect } from "react";
import Spinner from "@components/Spinner/Spinner";
import { useLocation } from "react-router-dom";
import notFound from "@assets/not-found.svg";
import Test from "@components/Test/Test";
import { setScore as dbSetScore } from "../../../../db";
import { UserAuth } from "../../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const FreeTest = () => {
  const url = "https://questions.aloc.com.ng/api/v2/q/40?";
  const [testQuestions, setTestQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [start, setStart] = useState(false);
  const location = useLocation();
  const { subject } = location.state;
  const duration = 40;
  const duration_in_ms = duration * 60 * 1000;
  const time_to_solve_1 = duration / testQuestions.length;
  const [answers, setAnswers] = useState([]);
  const [endTest, setEndTest] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Get test Questions
    const getTest = () => {
      axios
        .get(url + `subject=${subject}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            AccessToken: constants.TOKEN,
          },
        })
        .then((res) => {
          setTestQuestions(res.data.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    };

    if (start === false) {
      getTest();
    }
  }, []);

  const {user} = UserAuth()
  const navigate = useNavigate()

  const intro = (
    <div className="test-intro">
      <div className="test-detail">
        <h2 className="test-intro-subject">{subject} Test</h2>
        <div className="test-instruction">
          <h4>INSTRUCTIONS</h4>
          <p>Hello!</p>
          <p>
            This test consists of {testQuestions.length} questions. The time to
            solve one question is {time_to_solve_1}{" "}
            {time_to_solve_1 > 1 ? "minutes" : "minute"}.
          </p>
          <p>Make sure you have enough time and then start the test.</p>
          <p>Good Luck!</p>
        </div>
        <button className="btn" onClick={() => setStart(true)}>
          Start Test
        </button>
      </div>
    </div>
  );

  const submitTest = async () => {  // Function for submitting test
    let score = 0;
    if (endTest) {
      answers.map((answer) => {
        if(testQuestions[answer.id].answer === answer.value){
          score++;
        }
      });
      score*=2.5;
    }

    await dbSetScore({id: user.uid, score: score})

    navigate('/user/show-scores', {
      state: {
        score: score
      }
    })
  };

  const notFoundComponent = (
    <div className="q-not-found">
      <img src={notFound} alt="not found" />
      <h2>Unable to get questions</h2>
    </div>
  );

  return (
    <div
      className="test-wrapper"
      style={{ display: "flex", flexDirection: "column" }}
    >
      {open && (
        <>
          <div
            className="overlay"
            style={{ display: "block" }}
            onClick={() => setOpen(false)}
          />
          <div
            className="modal-container"
            style={{ justifySelf: "center", margin: "2rem auto" }}
          >
            <div className="modal">
              <div className="modal-message">Do you want to submit?</div>
              <button
                type="submit"
                className="btn"
                onClick={() => submitTest()}
              >
                Submit
              </button>
            </div>
          </div>
        </>
      )}
      {loading === true ? (
        <Spinner />
      ) : testQuestions.length > 0 && start === false ? (
        intro
      ) : start === true ? (
        <Test
          questions={testQuestions}
          subject={subject}
          duration={duration_in_ms}
          setAnswers={setAnswers}
          setEndTest={setEndTest}
          setOpen={setOpen}
          answers={answers}
        />
      ) : (
        notFoundComponent
      )}
    </div>
  );
};

export default FreeTest;
