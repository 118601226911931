import React from "react";
import "./Note.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import mathIcon from '@assets/pi-symbol.png';
import chemistyIcon from '@assets/chemistry.png';
import englishIcon from '@assets/english.png';
import physicsIcon from '@assets/physics.png';
import biologyIcon from '@assets/biology.png';

const Note = ({ note, admin }) => {
  let icon = englishIcon;
  switch (note.subject.toLowerCase()) {
    case 'mathematics':
      icon = mathIcon;
      break;
    case 'physics':
      icon = physicsIcon;
      break;
    case 'biology':
      icon = biologyIcon;
      break;
    case 'chemistry':
      icon = chemistyIcon;
      break;
    default:
      break;
  }

  return (
    <div className="note card">
      <div>{note.subject}</div>
      <img src={icon} alt="icon" />
      <div>{note.topic}</div>
      <div>
        <FontAwesomeIcon icon={faClock} />
        <span>{note.duration} read</span>
      </div>
      <button className="btn">{admin ? "View" : note.free ? "Start Reading": "Upgrade to View"}</button>
    </div>
  );
};

export default Note;
