import React from "react";
import "./Blogs.css";
import Panel from "../components/Panel/Panel";
import Sidebar from "../components/Sidebar/Sidebar";
import { useEffect, useState } from "react";
import Spinner from "@components/Spinner/Spinner";
import Footer from "@components/Footer/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { getAllBlogs } from "../../../db";

const Blogs = () => {
  const [loading, setLoading] = useState(false);
  const [blogsData, setBlogsData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      let res = await getAllBlogs()
      setBlogsData(res)
      setLoading(false)
    }

    getData();
  }, []);


  return (
    <div className="page-wrapper">
      <Sidebar id={3} />
      <div className="container">
        <Panel />
        {loading ? (
          <Spinner />
        ) : (
          <div className="page-content">
            <div className="overlay"></div>
            <div className="dash-nav-heading admin-notes-heading">
              <h1>Your Blogs</h1>
              <button
                className="btn"
                onClick={() => {
                  navigate("/blog/create");
                }}
              >
                Create Blog
                <span>
                  <FontAwesomeIcon icon={faPlus} />
                </span>
              </button>
            </div>
            <div className="blog-container">
              { blogsData.length > 0 ? blogsData.map((blog, i) => (
                <div className="blog" key={i}>
                  <img src={blog.imageUrl} alt="" />
                  <div>{blog.title}</div>
                  <div className="tags">
                    {blog.tags.map((tag, i) => (
                      <span className="tag" key={i}>
                        {tag}
                      </span>
                    ))}
                  </div>
                  <button className="btn" onClick={() => {
                    navigate("/blog/edit", {state: {data: blog}})
                  }} >Edit</button>
                </div>
              )): <div className="blog-container">
                <h1>No Blogs Yet</h1>
                </div>}
            </div>
            <Footer />
          </div>
        )}
      </div>
    </div>
  );
};

export default Blogs;
