import React from "react";
import './Preview.css'
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkMath from "remark-math";
import remarkGfm from "remark-gfm";
import rehypePrism from "@mapbox/rehype-prism"
import rehypeMathJax from "rehype-mathjax";
// import rehypeRemoveStyleTypeCss from "rehype-remove-style-type-css";
import "katex/dist/katex.min.css";
import "./prism.css"


const Preview = ({ markdown }) => {
  return (
    <div className="preview">
      <ReactMarkdown
        children={markdown}
        remarkPlugins={[remarkMath, remarkGfm,]}
        rehypePlugins={[rehypePrism, rehypeMathJax]}
        linkTarget="_blank"
      />
    </div>
  );
};

export default Preview;
