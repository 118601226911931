import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import routes from "./routes";
import { AuthContextProvider } from "./contexts/AuthContext";
import { AuthProvider, useFirebaseApp } from "reactfire";
import { ToastContainer} from "react-toastify";
import { getAuth } from "firebase/auth";

const App = () => {
  const app = useFirebaseApp();
  const auth = getAuth(app);
  return (
    <AuthProvider sdk={auth} >
      <div className="App">
        <ToastContainer />
        <AuthContextProvider>
          <Router>{routes}</Router>
        </AuthContextProvider>
      </div>
    </AuthProvider>
  );
};

export default App;
