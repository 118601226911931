import React from "react";
import "./Panel.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Notifications from "@components/Notifications/Notifications";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const Panel = () => {
  const mobile = window.innerWidth <= 900;
  const [showPanel, setShowPanel] = useState(false);
  const [showMenu, setShowMenu] = useState(true);

  const handleShowPanel = () => {
    setShowPanel(!showPanel);
  };

  const navigate = useNavigate();

  const handleTestClick = () => {
    navigate("/admin/tests");
  };

  const handleShowMenu = () => {
    setShowMenu(!showMenu);
    if (showMenu === true) {
      document.getElementById("sidebar").style.display = "flex";
      document.getElementById("overlay").style.display = "block";
    } else {
      document.getElementById("overlay").style.display = "none";
      document.getElementById("sidebar").style.display = "none";
    }
  };
  return (
    <>
      {mobile === true ? (
        <div className="panel panel-icons">
          <div
            className={showMenu === false ? "ellipsis show-right" : "ellipsis"}
            onClick={handleShowMenu}
          >
            {showMenu === true ? (
              <FontAwesomeIcon icon={faEllipsisVertical} />
            ) : (
              <FontAwesomeIcon icon={faXmark} />
            )}
          </div>

          {showMenu === true ? (
            <Notifications />
          ) : null}

          {showMenu === true ? (
            <div className="panel-icon" onClick={handleShowPanel}>
              {showPanel === false ? (
                <FontAwesomeIcon icon={faBars} />
              ) : (
                <FontAwesomeIcon icon={faXmark} />
              )}
            </div>
          ) : null}
        </div>
      ) : null}

      {mobile === false || showPanel === true ? (
        <div className="panel panel-content">
          <div className="panel-left">
            <div className="cta-admin">Create Notes</div>
            <span className="divider"></span>
            <div className="cta-admin">Create Blogs</div>
          </div>

          <div className="panel-right">
            <span onClick={handleTestClick} className="cta-d test-link">Create Tests</span>
            <span className="divider"></span>
            {mobile === true ? null : (
              <Notifications />
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Panel;
