import React from "react";
import "./Leaderboard.css";
import { LeaderboardData } from "../../data/leaderboardData";
import { useEffect } from "react";
import { useState } from "react";

const Leaderboard = () => {
  const [leaderboardList, setLeaderboardList] = useState([]);
  let limit = 5;
  useEffect(() => {
    let dataList = [];
    LeaderboardData.sort(sortFunction);
    LeaderboardData.map((data, i) => {
      if (i < limit) {
        dataList.push(data);
      }
    });

    setLeaderboardList(dataList);
  }, []);
  return (
    <div className="leaderboard-container">
      <h2>Weekly Leaderboard</h2>
      <div className="leaderboard">
        <div className="leaderboard-heading">
          <span>Position</span>
          <span>Name</span>
          <span>Score</span>
        </div>
        <div className="leaderboard-item">
          No Leaderboards Yet
        </div>
      </div>
    </div>
  );
};

const sortFunction = (a, b) => {
 if (a.score === b.score) {
    return 0
 } else {
    return (a.score > b.score)? -1 : 1;
 }
}

export default Leaderboard;
