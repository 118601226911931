import React from "react";
import "./CreateNote.css";
import TextIcon from "@assets/text.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPen } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import Editor from "../../components/Editor/Editor";
import { setNote } from "../../../../db";

const CreateNote = () => {
  const [showSubtitle, setShowSubtitle] = useState(false);
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [free, setFree] = useState(false);
  const [open, setOpen] = useState(false);
  const [subject, setSubject] = useState("")

  const openModal = () => {
    setOpen(true);
  };

  const handleSubmit = async (title, subtitle, note, isFree, subject) => {
    await setNote({
      title: title,
      subtitle: subtitle,
      note: note,
      isFree: isFree,
      subject: subject,
    });
  };

  const handlePublish = async () => {
    setOpen(false);
    await handleSubmit(title, subtitle, text, free, subject)
  };

  const style = open ? { display: "flex" } : { display: "none" };

  return (
    <div className="create-note">
      <div className="overlay" style={style}></div>
      <div className="heading">
        <div className="h-left">
          <FontAwesomeIcon icon={faPen} />
          <h2>Create Note</h2>
        </div>
        <div className="h-right">
          <button>Save Draft</button>
          <button onClick={openModal}>Publish</button>
        </div>
      </div>

      <div className="note-forms">
        <div className="modal-container" style={style}>
          <div className="modal">
            <div className="modal-message">
              Do you want to publish this note?
            </div>
            <label>
              <input
                type="checkbox"
                onChange={(e) => setFree(e.target.value)}
              />
              <span>Do you want it to be free?</span>
            </label>
            <button type="submit" className="btn" onClick={handlePublish}>
              Publish
            </button>
          </div>
        </div>
        {!showSubtitle ? (
          <div className="add-subtitle">
            <button onClick={() => setShowSubtitle(true)}>
              <img src={TextIcon} alt="icon" />
              <span>Add Subtitle</span>
            </button>
          </div>
        ) : null}

        <div className="subject-container">
          <input
            type="text"
            className="material-subtitle"
            placeholder="Subject"
            onChange={(e) => setSubject(e.target.value)}
          />
        </div>

        <div className="title-container">
          <textarea
            placeholder="Note Title ..."
            className="material-title"
            style={{ height: "84px !important" }}
            onChange={(e) => setTitle(e.target.value)}
          ></textarea>
        </div>

        {showSubtitle ? (
          <div className="subtitle-container">
            <textarea
              placeholder="Note Subtitle ..."
              className="material-subtitle"
              style={{ height: "66px !important" }}
              onChange={(e) => setSubtitle(e.target.value)}
            ></textarea>

            <button onClick={() => setShowSubtitle(false)}>
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
        ) : null}

        <Editor text={text} textHandler={setText} />
      </div>
    </div>
  );
};

export default CreateNote;
