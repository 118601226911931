import React from "react";
import "./TestPage.css";
import Panel from "../components/Panel/Panel";
import SideBar from "../components/SideBar/SideBar";
import { SubjectsData } from "@data/SubjectsData";
import { Link } from "react-router-dom";
import Footer from "@components/Footer/Footer";
import { useNavigate } from "react-router-dom";

const TestPage = () => {
  const navigate = useNavigate();

  return (
    <div className="wrapper">
      <div className="page-wrapper">
        <SideBar id={2} />
        <div className="container">
          <Panel />

          <div className="page-content">
            <div className="test-content" style={{ minHeight: "710px" }}>
              <div className="test-container">
                <div className="overlay" id="overlay"></div>
                <h1>Free Tests</h1>
                <div className="tests card">
                  {SubjectsData.map((item, i) => (
                    <div className="test-items" key={i}>
                      <div>{item.subject}</div>
                      <Link
                        to="/user/free-test"
                        state={{ subject: item.subject }}
                      >
                        <button className="btn">Take Test</button>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>

              <div className="test-container">
                <h1>Jamb Standard</h1>

                <div className="jamb-standard-container">
                  <div className="tests card js-left">
                    <h2>Free</h2>
                    <p>
                      Practice previous years jamb past questions and improve
                      your speed and accuracy. Start taking JAMB standard test
                      now with the free plan if you haven't upgraded yet, may
                      JAMB not jamb you 😇
                    </p>
                    <p>Pick your 4 subjects</p>
                    <p>
                      You get a total of <b>50 questions</b>{" "}
                      {"(20) for english"} and the rest are 10 each 🙂
                    </p>
                    <p>
                      Your duration is <b>35 mins</b>, so buckle up 😉
                    </p>
                    <button
                      className="btn"
                      onClick={() => navigate("/free/jamb-test")}
                    >
                      Take Test
                    </button>
                  </div>
                  <div className="tests card js-right">
                    <h2>Premium</h2>
                    <p>
                      Get the most experience my practicing with carefully
                      selected past questions. We have added custom questions
                      that will help you build your problem solving skills 🔥
                    </p>
                    <p>Pick your 4 subjects</p>
                    <p>
                      You get a total of <b>180 questions</b>{" "}
                      {"(60) for english"} and the rest are 40 each 🙂
                    </p>
                    <p>
                      Your have <b>2 hrs</b> which is enough time for you to get
                      those marks 😉
                    </p>
                    <button className="btn">Upgrade your account</button>
                  </div>
                </div>
              </div>

              <div className="test-container">
                <h1>Daily Tests</h1>
                <div className="jamb-standard-container">
                  <div className="tests card js-left" style={{width: '50%'}}>
                    <h2>JAMB</h2>
                    <p>
                      Practice Tests Based on what was taught today😇
                    </p>
                    <p>Pick your 4 subjects</p>
                    <p>
                      You get a total of <b>50 questions</b>{" "}
                      {"(20) for english"} and the rest are 10 each 🙂
                    </p>
                    <p>
                      Your duration is <b>35 mins</b>, so buckle up 😉
                    </p>
                    <button
                      className="btn"
                      onClick={() => navigate("/free/jamb-test")}
                    >
                      Take Test
                    </button>
                  </div>

                  <div className="js-left"></div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestPage;
