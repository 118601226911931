import React from "react";

const TestButtons = ({
  mode,
  selected,
  setSelected,
  questions,
  open,
  setSubject,
  subjectId,
  setEndTest
}) => {
  return (
    <div className="test-buttons">
      <button
        className="btn"
        onClick={() => {
          if (selected > 0) {
            setSelected(selected - 1);
            let slider = document.getElementById("scroll-numbers");
            slider.scrollLeft -= 80;
          }
        }}
      >
        Previous
      </button>

      {mode === "jamb" && (
        <button className="btn" onClick={() => {
            subjectId < 3 ? setSubject(subjectId + 1) : setSubject(0)
        }}>
          Next Subject
        </button>
      )}

      <button
        className="btn"
        onClick={(e) => {
          e.preventDefault();
          if (selected + 1 < questions.length) {
            setSelected(selected + 1);
            if (selected >= 3) {
              let slider = document.getElementById("scroll-numbers");
              slider.scrollLeft += 80;
            }
          } else {
            setEndTest(true)
            open(true)
          }
        }}
      >
        {selected + 1 === questions.length ? "Submit" : "Next"}
      </button>
    </div>
  );
};

export default TestButtons;
