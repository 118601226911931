import React from "react";
import "./SideBar.css";
import profileImage from "@assets/profile-image.png";
import { UserAuth } from "../../../../contexts/AuthContext";
import Spinner from "../../../../components/Spinner/Spinner";

const SideBar = ({ blogData, loading }) => {
  const { user } = UserAuth();
  var len = blogData.length;
  const recentBlogs = blogData.slice(len - 4, len);
  return (
    <div className="blog-sidebar">
      <div className="user-info">
        <img src={profileImage} alt="" />
        <div className="name">{user && user.displayName}</div>
        <div>Student</div>
        <a href="/user/dashboard" style={{ color: "white" }}>
          dashboard
        </a>
      </div>
      <br />
      <div className="recent-blogs">
        <h2>Recent Blogs...</h2>
        <div className="recent-blog-items">
          {loading ? (
            <Spinner />
          ) : (
            recentBlogs.map((blog, i) => (
              <div className="r-blog-item" key={i}>
                <h3>{blog.title}</h3>
                <div className="blogs-info">
                  <p>6 mins read</p>
                  <button className="btn">read more -&gt;</button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default SideBar;
