import React from "react";
import "./Test.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import TestPanel from "./components/TestPanel";
import QuestionNumber from "./components/QuestionNumber";
import TestButtons from "./components/TestButtons";
import Questions from "./components/Questions";

const Test = ({
  mode,
  questions,
  subject,
  duration,
  setSubject,
  subjectId,
  setEndTest,
  setAnswers,
  answers,
  setOpen,
}) => {
  const now_in_ms = new Date().getTime();
  const targetTime = now_in_ms + duration;
  // State for ending test
  const [selected, setSelected] = useState(0);
  const [doneQuestions, setDoneQuestions] = useState([]);
  const [skippedQuestions, setSkippedQuestions] = useState([]);

  var qNumbers = [];
  for (let i = 1; i < questions.length + 1; i++) {
    qNumbers.push(i);
  }

  const handleChange = (e, i) => {
    // Filters the answer incase it has been clicked before
    let updatedAnswer = answers.filter(answer => answer.id !== i)
    setAnswers([...updatedAnswer, {id: i, value: e.target.value}])
    setDoneQuestions([...doneQuestions, i + 1]);
    setSkippedQuestions(skippedQuestions.filter((item) => item !== i + 1));
  };

  return (
    <div
      className={
        mode === "jamb" ? "portal jamb-portal noselect" : "portal noselect"
      }
      id="test-form"
    >
      {!mode && (
        <TestPanel
          subject={subject}
          targetTime={targetTime}
          setEndTest={setEndTest}
          mode={mode}
        />
      )}

      <QuestionNumber
        selected={selected}
        questions={questions}
        qNumbers={qNumbers}
        doneQuestions={doneQuestions}
        skippedQuestions={skippedQuestions}
      />

      {/* Display Questions*/}
      {questions.map((question, i) => (
        <Questions
          key={i}
          question={question}
          selected={selected}
          i={i}
          handleChange={handleChange}
        />
      ))}

      <div
        className={
          skippedQuestions.includes(selected + 1)
            ? "mark-r skip-button"
            : "mark-r"
        }
        onClick={() => {
          setSkippedQuestions([...skippedQuestions, selected + 1]);
        }}
      >
        <FontAwesomeIcon icon={faFlag} />
        Mark for Later
      </div>

      <TestButtons
        selected={selected}
        setSelected={setSelected}
        setSubject={setSubject}
        questions={questions}
        setEndTest={setEndTest}
        open={setOpen}
        mode={mode}
        subjectId={subjectId}
      />
    </div>
  );
};

export default Test;
