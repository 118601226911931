import React from "react";
import "./Spinner.css";
import ScaleLoader from "react-spinners/ScaleLoader";

const Spinner = () => {
  return (
    <div className="spinner">
      <ScaleLoader color="#64C3E8" size={150} />
    </div>
  );
};

export default Spinner;
