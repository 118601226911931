import {
  setDoc,
  doc,
  getDoc,
  updateDoc,
  collection,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { db, storage } from "../firebase";
import { ref, uploadString, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";

export const createUserData = async (id, name, email) => {
  const userRef = doc(db, "users", `${id}`);
  const data = {
    name: name,
    email: email,
    isAdmin: false,
    subscribed: false,
    streak: 0,
    notesRead: 0,
    createdAt: new Date(),
  };
  await setDoc(userRef, data)
    .then(async () => {
      await addUsers();
    })
    .catch((err) => console.log(err.message));
};

const addUsers = async () => {
  const totalRef = doc(db, "totalUsers", "total-users");
  const docSnap = await getDoc(totalRef);
  if (docSnap.exists()) {
    let data = docSnap.data();
    data.total += 1;
    await setDoc(totalRef, data);
  } else {
    const data = {
      total: 1,
    };
    await setDoc(totalRef, data);
  }
};

export const getUserData = async (id) => {
  const userRef = doc(db, "users", `${id}`);
  const docSnap = await getDoc(userRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    console.log("No Such Data");
  }
};

export const getNotesData = async () => {};

export const setNote = async ({ title, subtitle, note, isFree, subject }) => {
  const wpm = 225;
  const id = uuidv4();
  const words = note.trim().split(/\s+/).length;
  const time = Math.ceil(words / wpm);

  const data = {
    topic: title,
    subtitle: subtitle,
    subject: subject,
    free: isFree,
    duration: time,
    note: note,
    createdAt: new Date(),
  };

  try {
    const noteRef = doc(db, "notes", `${id}`);
    await setDoc(noteRef, data);

    const noteDataRef = doc(db, "notesData", `data-${id}`);
    const noteData = {
      id: id,
      subject: subject,
      topic: title,
      duration: time,
      free: isFree,
    };
    await setDoc(noteDataRef, noteData);
    return "Note Created Successfully";
  } catch (error) {
    return error.message;
  }
};

const getDate = () => {
  let date = new Date();
  let day = date.getDate();
  let month = date.getMonth();
  let year = date.getFullYear();

  return `${day}-${month + 1}-${year}`;
};

export const setDailyQuestions = async (subject, testData) => {
  try {
    const id = uuidv4();

    const data = {
      ...testData,
      id,
    };

    // For using the date as the collection id

    let date = getDate();

    const docRef = doc(db, `${subject}`, `${date}`);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let qData = docSnap.data();
      await updateDoc(docRef, { questions: [...qData.questions, data] });
    } else {
      await setDoc(docRef, { questions: [data] });
    }

    return "Question Added Successfully";
  } catch (error) {
    return console.log(error.message);
  }
};

export const getDailyQuestions = async (subject) => {
  let date = getDate();
  const docRef = doc(db, `${subject}`, `${date}`);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    console.log("No Daily Questions Yet");
  }
};

export const setScore = async ({ id, score }) => {
  try {
    const scoreRef = doc(db, `scores`, `${id}`);
    const docSnap = await getDoc(scoreRef);

    if (docSnap.exists()) {
      let data = docSnap.data();
      data.scores.push(score);
      await setDoc(scoreRef, data);
    } else {
      const data = {
        scores: [score],
      };
      await setDoc(scoreRef, data);
    }

    console.log("Score added successfully");
  } catch (error) {
    console.log(error.message);
  }
};

export const getScore = async (id) => {
  try {
    const scoreRef = doc(db, "scores", `${id}`);
    const docSnap = await getDoc(scoreRef, orderBy('scores'));

    if (docSnap.exists()) {
      let data = docSnap.data();
      return data.scores;
    } else {
      return 0;
    }
  } catch (error) {
    return error.message;
  }
};

// for uploading images
export const uploadImage = async (file) => {
  try {
    const storageRef = ref(storage, file.name);
    await uploadString(storageRef, file.base64, "data_url");

    let downloadUrl = await getDownloadURL(storageRef);
    console.log("Image added successfully")

    return {success:"image added successfully", url: downloadUrl};
  } catch (error) {
    return {error: error.message};
  }
};

export const setBlog = async (data) => {
  try {
    const id = uuidv4();
    const createdAt = new Date();
    const likes = 0;
    const title = data.title.split(" ").join("-"); // Splits the title and join them using "-" as the separator, to be used for blog ID
    const docRef = doc(db, "blogs", `${title}-${id}`);
    await setDoc(docRef, { id, likes, createdAt, ...data });
    return "Blog created successfully";
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateBlog = async (id, data) => {
  try {
    const docRef = doc(db, "blogs", `${id}`);
    await updateDoc(docRef, { ...data });
    return "Blog updated successfully"
  } catch (error) {
    throw new Error(error.message);
  }
}

export const getBlog = async (id) => {
  try {
    const docRef = doc(db, "blogs", `${id}`);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return "No blogs found";
    }
  } catch (error) {
    return error.message;
  }
};

export const getAllBlogs = async () => {
  try {
    var data = []
    const querySnapshot = await getDocs(collection(db, "blogs"));
    querySnapshot.forEach((doc) => {
      let docSnap = doc.data()
      data.push(docSnap)
    });

    return data;
  } catch (error) {
    return error.message;
  }
};


export const setNotification = async (data, userId) => {
  try {
    const id = uuidv4();
    const date = new Date();
    const createdAt = `${date}`;
    const docRef = doc(db, "notifications", `${userId}`);
    await setDoc(docRef, { id, createdAt, ...data });
  } catch (error) {
    return error.message;
  }
};


// Comment Apis
export const sendComment = async (id, data) => {
  try {
    const date = new Date();
    const createdAt = `${date}`;
    const docRef = doc(db, "comments", `${id}`)
    const docSnap = await getDoc(docRef)

    let commentData = {
      ...data,
      createdAt,
    }

    if (docSnap.exists()) {
      let cData = docSnap.data();
      console.log(cData)
      await updateDoc(docRef, { comments: [...cData.comments, commentData] });
    } else {
      await setDoc(docRef, { comments: [commentData] });
    } 

    console.log("Comment Added Successfully")
    return {type: "success", message: "Comment Added Successfully"}
  } catch (err) {
    return err.message
  }
}

export const getComments = async (id) => {
  try {
    const docRef = doc(db, "comments", `${id}`);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return {};
    }
  } catch (error) {
    return error.message;
  }
}