import "./Blogs.css";
import React from "react";
import { blogData } from "@data/blogsData";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom"

const Blogs = () => {
  const navigate = useNavigate()
  const mobile = window.innerWidth <= 768;
  const bLength = blogData.length;
  const [blogStart, setBlogStart] = useState(0);
  let idx = mobile === true ? 1 : 3;
  const [blogEnd, setBlogEnd] = useState(idx);
  let blogs = [...blogData.slice(blogStart, blogEnd)];

  const shiftRight = () => {
    if (blogEnd !== bLength) {
      setBlogStart((prev) => prev + 1);
      setBlogEnd((prev) => prev + 1);
    } else {
      setBlogStart((prev) => prev);
      setBlogEnd((prev) => prev);
    }
  };

  const shiftLeft = () => {
    if (blogStart !== 0) {
      setBlogStart((prev) => prev - 1);
      setBlogEnd((prev) => prev - 1);
    } else {
      setBlogStart((prev) => prev);
      setBlogEnd((prev) => prev);
    }
  };

  const blog = (
    <div className="blogs">
      {blogs.map((blog, i) => (
        <div className="blog" key={i}>
          <img src={blog.image} alt="" />
          <div>{blog.title}</div>
          <div>{blog.details.slice(0, 200)}...</div>
          <div className="tags">
            {blog.tags.map((tag, i) => (
              <span className="tag" key={i}>
                {tag}
              </span>
            ))}
          </div>
          <button className="btn">Read More</button>
        </div>
      ))}
    </div>
  );

  const redirectBlogs = () => {
    navigate('/blogs')
  }

  return (
    <div className="blogs-wrapper" id="Blogs">
      <div className="blogs-header">
        <span className="stroke-text">Our</span> <span>Blogs</span>
      </div>
      <div className="blog-wrapper">
        <div className="blog-arrow" onClick={shiftLeft}>
          <FontAwesomeIcon icon={faArrowLeft} fontSize="25px" />
        </div>

        {blog}

        <div className="blog-arrow" onClick={shiftRight}>
          <FontAwesomeIcon icon={faArrowRight} fontSize="25px" />
        </div>
      </div>

      <div className="allBlogs" onClick={redirectBlogs}>see all blogs -&gt;</div>
    </div>
  );
};

export default Blogs;
