import React from "react";
import "./RecNotes.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const RecNotes = (notes) => {
  return (
    <>
      <div className="rec-heading">
        <span>Recommended Notes</span>

        <div className="rec-arrows">
          <span
            onClick={() => {
              const slider = document.getElementById("slider");
              slider.scrollLeft = slider.scrollLeft - 275;
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </span>

          <span
            onClick={() => {
              const slider = document.getElementById("slider");
              slider.scrollLeft = slider.scrollLeft + 275;
            }}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </span>
        </div>
      </div>

      {notes.length > 0 ? <div className="rec-notes snap-inline" id="slider">
        {notes.map((note, i) => (
          <div className="rec-note card" key={i}>
            <div className="rec-note-heading">
              <span>{note.topic}</span>
              <button>Read</button>
            </div>
            <div>
              <FontAwesomeIcon icon={faClock} />
              {note.duration} read
            </div>
          </div>
        ))}
      </div>: <div style={{padding: '2rem'}}>No Notes Yet</div>}
    </>
  );
};

export default RecNotes;
