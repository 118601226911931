import React from "react";
import './Tests.css'
import { useEffect, useState } from "react";
import Spinner from "@components/Spinner/Spinner";
import Footer from "@components/Footer/Footer";
import { SubjectsData } from "@data/SubjectsData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import SideBar from "../components/Sidebar/Sidebar";
import Panel from "../components/Panel/Panel";
import Question from "../components/Question/Question";

const Tests = () => {
  const navigate = useNavigate();
  const [subjects, setSubjects] = useState([])

  useEffect(() => {
    setSubjects(SubjectsData);
  }, [])

  return (
    <div className="page-wrapper">
      <SideBar id={2} />
      <div className="container">
        <Panel />
        <div className="page-content">
          <div className="overlay"></div>

          <div className="dash-nav-heading admin-notes-heading">
            <h1>Your Questions</h1>
            <button
              className="btn"
              onClick={() => {
                navigate("/test/create/new");
              }}
            >
              Add Subject
              <span>
                <FontAwesomeIcon icon={faPlus} />
              </span>
            </button>
          </div>

          <div className="questions-container">
            {subjects.map((subject, i) => (
                <Question subject={subject} key={i}/>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tests;
