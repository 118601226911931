import React from "react";
import "./JambScores.css";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const JambScores = () => {
    const location = useLocation()
    const subjects = location.state.subjects
    const scores = location.state.scores
    const average = location.state.average
    const navigate = useNavigate();
  return (
    <div className="show-scores">
      <div className="scores card">
        <span>Congratulations on completing your test</span>
        <span>Your Scores: </span>
        <div className="subject-scores">
            {subjects.map((subject, i) => (
                <div key={i}>
                    {subject} : {scores[i]}
                </div>
            ))}
        </div>

        <div className="total">
            Total: {average * 4}
        </div>

        <div className="average">
            Average: {average}
        </div>
        <button className="btn" onClick={() => navigate("/user/dashboard")}>
          Continue
        </button>
      </div>
    </div>
  );
};

export default JambScores;
