import commaNumber from "comma-number";

const formatValue = (value) => {
    let rem;
    if (value % 1000000 === 0) {
      rem = value / 1000000;
      return `${rem}M`;
    } else if (value > 10000 && value % 1000 === 0) {
      rem = commaNumber(value / 1000);
      return `${rem}K`;
    } else {
      return commaNumber(value)
    }
  };


export default formatValue;
