import React from "react";
import "./CallToAction.css";
import ctaIllustration from '@assets/cta-il.svg'
import { useNavigate } from "react-router-dom";

const CallToAction = () => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate("/auth/signup")
  }
  return (
    <div className="call-to-action">
      <div className="cta-l">
        <h1>
          Want to get 300+ your upcoming JAMB?
        </h1>
        <p>
          Join our students now and ace your examinations with ease
        </p>

        <button className="btn" onClick={handleClick} >Sign Up</button>
      </div>
      <div className="cta-r">
        <div></div>
        <div></div>
        <img src={ctaIllustration} alt="" />
      </div>
    </div>
  );
};

export default CallToAction;
