const toolbarFunctions = ({ name, markdown, setMarkdown }) => {
  let newMarkdown = "";
  let text = "";
  switch (name) {
    case "heading1":
      newMarkdown = insertHeading(markdown, 1);
      break;
    case "heading2":
      newMarkdown = insertHeading(markdown, 2);
      break;
    case "heading3":
      newMarkdown = insertHeading(markdown, 3);
      break;
    case "bold":
      text = " **<Insert Text Here>** ";
      newMarkdown = insertTextToPos(markdown, text);
      break;
    case "italic":
      text = "*<Insert Text Here>*";
      newMarkdown = insertTextToPos(markdown, text);
      break;
    case "list":
      text = "- <Insert Text Here>";
      newMarkdown = insertTextToPos(markdown, text);
      break;
    case "listOl":
      text = "1. <Insert Text Here>";
      newMarkdown = insertTextToPos(markdown, text);
      break;
    case "link":
      text = "[Text](Link)";
      newMarkdown = insertTextToPos(markdown, text);
      break;
    case "blockquote":
      text = "> Insert Text Here";
      newMarkdown = insertTextToPos(markdown, text);
      break;
    case "highlight":
      text = " ``` Insert Texts Here ```"
      newMarkdown = insertTextToPos(markdown, text);
      break;
    case "math expressions":
        text = "$$ <Insert your expressions here> $$"
        newMarkdown = insertTextToPos(markdown, text)
        break;
    default:
      break;
  }

  setMarkdown(newMarkdown);
};

const insertHeading = (markdown, type) => {
  let text = "";
  let newMarkdown = "";
  switch (type) {
    case 1:
      text = "# <Insert Text Here>";
      newMarkdown = insertTextToPos(markdown, text);
      return newMarkdown;
    case 2:
      text = " ## <Insert Text Here>";
      newMarkdown = insertTextToPos(markdown, text);
      return newMarkdown;
    case 3:
      text = " ### <Insert Text Here>";
      newMarkdown = insertTextToPos(markdown, text);
      return newMarkdown;
    default:
      return markdown;
  }
};

const insertTextToPos = (markdown, text) => {
  let textArea = document.getElementById("editor");
  const curPos = textArea.selectionStart;
  let newMarkdown = markdown.slice(0, curPos) + text + markdown.slice(curPos);
  textArea.focus();

  return newMarkdown;
};

export default toolbarFunctions;
