import React from "react";
import "./Question.css";
import testIcon from "@assets/test.svg";
import { Link } from "react-router-dom";

const Question = ({ subject }) => {

  return (
    <div className="question card">
      <img src={testIcon} alt="icon" />
      <div>{subject.subject}</div>
      <Link to="/test/questions" state={{subject}} className="button">
      <button className="btn">View Questions</button>
      </Link>
    </div>
  );
};

export default Question;
