import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./Provider/ProtectedRoute/ProtectedRoute";
import AdminProtectedRoute from "./Provider/AdminProtectedRoute/AdminProtectedRoute";
import {
  Dashboard,
  Home,
  Login,
  Notes,
  PageNotFound,
  ResetPassword,
  SignUp,
  TestPage,
  FreeTest,
  AdminDashboard,
  AdminNotePage,
  CreateNote,
  AdminBlogs,
  CreateBlog,
  Tests,
  ViewQuestions,
  FreeJambTest,
  ShowScores,
  JambScores,
  About,
  Blogs,
  Blog,
} from "./Pages";

const createRoutes = (
  <Routes>
    {/* Auth paths */}
    <Route path="/" element={<Home />} />
    <Route path="/about" element={<About />} />
    <Route path="/auth/login" element={<Login />} />
    <Route path="/auth/signup" element={<SignUp />} />
    <Route path="/auth/reset" element={<ResetPassword />} />
    {/* Dashboard paths */}
    <Route
      path="/user/dashboard"
      element={
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      }
    />
    <Route
      path="/user/notes"
      element={
        <ProtectedRoute>
          <Notes />
        </ProtectedRoute>
      }
    />
    <Route
      path="/user/tests"
      element={
        <ProtectedRoute>
          <TestPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="/user/free-test"
      element={
        <ProtectedRoute>
          <FreeTest />
        </ProtectedRoute>
      }
    />
    <Route
      path="/admin/dashboard"
      element={
        <AdminProtectedRoute>
          <AdminDashboard />
        </AdminProtectedRoute>
      }
    />

    {/* Note paths */}
    <Route
      path="/admin/notes"
      element={
        <AdminProtectedRoute>
          <AdminNotePage />
        </AdminProtectedRoute>
      }
    />
    <Route
      path="/note/create"
      element={
        <AdminProtectedRoute>
          <CreateNote />
        </AdminProtectedRoute>
      }
    />

    {/* Blog paths */}
    <Route
      path="/admin/blogs"
      element={
        <AdminProtectedRoute>
          <AdminBlogs />
        </AdminProtectedRoute>
      }
    />

    <Route
      path="/blog/create"
      element={
        <AdminProtectedRoute>
          <CreateBlog edit={false} />
        </AdminProtectedRoute>
      }
    />

    <Route
      path="/blog/edit"
      element={
        <AdminProtectedRoute>
          <CreateBlog edit={true} />
        </AdminProtectedRoute>
      }
    />

    <Route
      path="/blogs"
      element={
        <ProtectedRoute>
          <Blogs />
        </ProtectedRoute>
      }
    />

    <Route path="/blog/read/:id" element={<Blog />} />

    {/* Test paths */}
    <Route
      path="/admin/tests"
      element={
        <ProtectedRoute>
          <Tests />
        </ProtectedRoute>
      }
    />
    <Route
      path="/test/questions"
      element={
        <AdminProtectedRoute>
          <ViewQuestions />
        </AdminProtectedRoute>
      }
    />

    <Route
      path="/free/jamb-test"
      element={
        <ProtectedRoute>
          <FreeJambTest />
        </ProtectedRoute>
      }
    />

    <Route
      path="/user/show-scores"
      element={
        <ProtectedRoute>
          <ShowScores />
        </ProtectedRoute>
      }
    />

    <Route
      path="/user/show-jamb-scores"
      element={
        <ProtectedRoute>
          <JambScores />
        </ProtectedRoute>
      }
    />

    <Route path="*" element={<PageNotFound />} />
  </Routes>
);

export default createRoutes;
