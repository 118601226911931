import React, { useEffect } from "react";
import "./JambSubjectForm.css";
import { subjects } from "@data/jambSubjects";
import { useState } from "react";

const JambSubjectForm = ({subjectsTaken, setSubjectsTaken, setContinue}) => {
  const [error, setError] = useState("");

  useEffect(() => {
    subjectsTaken.length <= 4 ? setError("") : setError("You can select only 4 subjects")
  }, [subjectsTaken])

  const handleChange = (value) => {
    let checked = document.getElementById(value).checked;
    setData(checked, value);
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    if (subjectsTaken.length === 4) {
      setContinue(true)
    } else {
      setError("Select 4 Subjects")
    }
  }

  const setData = (checked, value) => {
      if (!checked) {
        let subjectList = subjectsTaken.filter((s) => s !== value);
        setSubjectsTaken(subjectList);
      } else {
        setSubjectsTaken([...subjectsTaken, value]);
      }
  };

  return (
    <form className="jsf-form">
      {error && <div className="form-error">{error}</div>}
      <h2>Select 4 Subjects</h2>
      {subjects.map((data, i) => (
        <label key={i}>
          <input
            type="checkbox"
            value={data.value}
            id={data.value}
            onChange={(e) => {
              handleChange(e.target.value);
            }}
          />
          <span>{data.subject}</span>
        </label>
      ))}

      <button
      className="btn"
        onClick={handleSubmit}
      >
        Continue
      </button>
    </form>
  );
};

export default JambSubjectForm;
