import React from "react";
import Panel from "../components/Panel/Panel";
import SideBar from "../components/SideBar/SideBar";
import "./Dashboard.css";
import SubjectsPanel from "../components/SubjectsPanel/SubjectsPanel";
import { useState, useEffect } from "react";
import Spinner from "@components/Spinner/Spinner";
import Reminder from "./components/Reminder/Reminder";
import Overview from "./components/Overview/Overview";
import RecNotes from "./components/RecNotes/RecNotes";
import NewsRem from "./components/NewsRem/NewsRem";
import Footer from "@components/Footer/Footer";
import { SubjectsData } from "@data/SubjectsData";
import { NotesData } from "@data/NotesData";
import Leaderboard from "@components/Leaderboard/Leaderboard";
import { UserAuth } from "../../../contexts/AuthContext";
import { getUserData, getScore } from "../../../db";

const Dashboard = () => {
  const {user} = UserAuth();
  const [loading, setLoading] = useState(false);
  const [subjectIndex, setSubjectIndex] = useState(1);
  const [notes, setNotes] = useState([]);
  const [userData, setUserData] = useState({streak: 0, notesRead: 0, name: ""});
  const [scores, setScores] = useState([0])
  
  // filters notes based on subject
  const filterNotes = (subject) => {  
    const filteredNotes = NotesData.filter((note) => note.subject === subject);
    setNotes(filteredNotes);
  };

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      const userId = localStorage.getItem("user-id");
      const scoreData = await getScore(userId);
      const data = await getUserData(userId);
      scoreData && setScores(scoreData);
      setUserData(data)
      setLoading(false);
    };
    filterNotes(SubjectsData[subjectIndex - 1].subject);
    getData();
  }, []);
  

  // Handler for selecting subject index and filtering note data based on subject selected
  const subjectHandler = (idx) => {
    setSubjectIndex(idx);
    if (idx === 0) {
      setNotes(NotesData);
    } else {
      filterNotes(SubjectsData[idx - 1].subject);
    }
  };


  return (
    <div className="page-wrapper">
      <SideBar id={0} />
      <div className="container">
        <Panel />
        {loading ? (
          <Spinner />
        ) : (
          user && (
            <div className="page-content">
              <div className="overlay" id="overlay"></div>
              <SubjectsPanel
                subjectData={SubjectsData}
                index={subjectIndex}
                handler={subjectHandler}
              />
              <div className="user-section">
                <div className="greeting">
                  <span>Hi,</span>
                  <span>{userData ? userData.name.split(" ")[0] : userData.name}👋</span>
                </div>
                <Overview score={scores[scores.length-1]} streak={userData && userData.streak} notesRead={userData && userData.notesRead} />
                <Reminder />
                <NewsRem />
              </div>
              <div className="recommendation">
                <RecNotes notes={notes} />
              </div>
              <Leaderboard />
              <div className="space"></div>
              <Footer />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Dashboard;
