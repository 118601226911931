import React, { useState } from "react";
import "./LoginForm.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../contexts/AuthContext";
import checkUserRole from "../../utils/checkUserRole";
import { toast } from "react-toastify";

const LoginForm = () => {
  const iconStyle = { color: "rgba(0,0,0,0.5)" };
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { login } = UserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    login(email, password)
      .then((userCredential) => {
        checkUserRole(userCredential.user.uid).then((isAdmin) => {
          setLoading(false);
          toast.success("Login successful");
          localStorage.setItem("user-id", userCredential.user.uid);
          localStorage.setItem("user-name", userCredential.user.displayName);
          isAdmin ? navigate("/admin/dashboard") : navigate("/user/dashboard");
        });
      })
      .catch(error => {
        setLoading(false);
        setError(error.message);
      });
  };

  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <div
        className="form-error"
        style={error ? { display: "block" } : { display: "none" }}
      >
        {error}
      </div>
      <div className="form-item">
        <FontAwesomeIcon icon={regular("user")} style={iconStyle} />
        <input
          type="email"
          name="login-email"
          id="login-email"
          placeholder="Email:"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className="form-item">
        <FontAwesomeIcon icon={solid("lock")} style={iconStyle} />
        <input
          type="password"
          name="login-password"
          id="login-password"
          placeholder="Password:"
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <div className="form-reminder">
        <div>
          <input type="checkbox" name="" id="" />
          <span>Remember</span> <span>me</span>
        </div>

        <a href="/auth/reset">Forgot your password ?</a>
      </div>

      <button type="submit" className="login-btn">
        {loading ? (
          <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
        ) : (
          "Log In"
        )}
      </button>
    </form>
  );
};

export default LoginForm;
