import React from 'react'
import Sidebar from '../components/Sidebar/Sidebar'
import './Notes.css'
import Subjects from '../../User/components/SubjectsPanel/SubjectsPanel'
import Panel from '../components/Panel/Panel'
import { useEffect, useState } from 'react'
import { SubjectsData } from "@data/SubjectsData";
import { NotesData } from "@data/NotesData";
import Spinner from "@components/Spinner/Spinner";
import Footer from "@components/Footer/Footer";
import Note from '../../User/Notes/Note/Note'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

const Notes = () => {
    const [loading, setLoading] = useState(false);
    const [subjectIndex, setSubjectIndex] = useState(1);
    const [notes, setNotes] = useState([]);
    const navigate = useNavigate();
  
    const filterNotes = (subject) => {
      const filteredNotes = NotesData.filter((note) => note.subject === subject);
      setNotes(filteredNotes);
    };
  
    useEffect(() => {
      filterNotes(SubjectsData[subjectIndex - 1].subject);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }, []);
  
    const subjectHandler = (idx) => {
      setSubjectIndex(idx);
      if (idx === 0) {
        setNotes(NotesData);
      } else {
        filterNotes(SubjectsData[idx - 1].subject);
      }
    };
  return (
    <div className="page-wrapper">
        <Sidebar id={1} />
        <div className="container">
            <Panel />
            {loading ? (
            <Spinner />
          ) : (
            <div className="page-content">
              <div className="overlay" id="overlay"></div>
              <Subjects
                subjectData={SubjectsData}
                index={subjectIndex}
                handler={subjectHandler}
              />
              <div className="note-container">
                <div className="dash-nav-heading admin-notes-heading">
                  <h1>Your Notes</h1>
                  <button 
                  className="btn"
                  onClick={() => {navigate('/note/create')}} 
                  >
                    Create Note 
                    <span><FontAwesomeIcon icon={faPlus} /></span>
                  </button>
                </div>
                <div className="notes">
                  {notes.map((note, i) => (
                    <Note note={note} admin={true} key={i} />
                  ))}
                </div>
              </div>
              <Footer />
            </div>
          )}
        </div>
    </div>
  )
}

export default Notes