import React, { useState, useEffect } from "react";
import "./FreeJambTest.css";
import axios from "axios";
import JambSubjectForm from "@forms/JambSubjectForm/JambSubjectForm";
import JambTest from "@components/JambTest/JambTest";
import constants from "@config/constants";
import Spinner from "@components/Spinner/Spinner"

const FreeJambTest = () => {
  const [continueTest, setContinueTest] = useState(false);
  const [subjectsTaken, setSubjectsTaken] = useState([]);
  const [questions1, setQuestions1] = useState([]);
  const [questions2, setQuestions2] = useState([]);
  const [questions3, setQuestions3] = useState([]);
  const [questions4, setQuestions4] = useState([]);

  const [loading, setLoading] = useState(false)
  // Api Url
  const url = "https://questions.aloc.com.ng/api/v2/q";

  useEffect(() => {
    if (continueTest) {
      let total = 0
      const [subject1, subject2, subject3, subject4] = subjectsTaken;
      // For getting individual Questions
      const getQuestion = (n, subject, setQuestion) => {
        axios
          .get(`${url}/${n}?subject=${subject}`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              AccessToken: constants.TOKEN,
            },
          })
          .then((res) => {
            setQuestion(res.data.data);
            total += res.data.data.length
            if (total === 50) {
              setLoading(false)
            }
          })
          .catch((err) => {
            console.log(err);
          });
      };

      // Logic for getting required number of questions
      const getTestQuestion = (subject, setQuestion) => {
        if (subject.toLowerCase() === "english") {
          getQuestion(20, subject, setQuestion);
        } else {
          getQuestion(10, subject, setQuestion);
        }
      };
    
      const getQuestions = () => {
        getTestQuestion(subject1, setQuestions1);
        getTestQuestion(subject2, setQuestions2);
        getTestQuestion(subject3, setQuestions3);
        getTestQuestion(subject4, setQuestions4);
      };

      setLoading(true)
      getQuestions()
    }
  }, [continueTest]);

  return (
    <div className="free-jamb-test">
      {!continueTest ? (
        <JambSubjectForm
          subjectsTaken={subjectsTaken}
          setSubjectsTaken={setSubjectsTaken}
          setContinue={setContinueTest}
        />
      ) : loading ?
      <Spinner />
      :(
        <JambTest
          questions={[questions1, questions2, questions3, questions4]}
          duration={35 * 60 * 1000}
          subjects={subjectsTaken}
        />
      )}
    </div>
  );
};

export default FreeJambTest;
