import React from "react";
import "./SignUp.css";
import { Link } from "react-router-dom";
import SignupForm from "@forms/SignupForm/SignupForm";
import graduation from "@assets/graduation.jpg";
import { useState, useEffect } from "react";
import Spinner from "../../../components/Spinner/Spinner";

const SignUp = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const style = {
    backgroundColor: "white",
    zIndex: 2,
  };
  return (
    <div className="loading-container">
      {loading ? (
        <Spinner />
      ) : (
        <div className="signup-container">
          <div className="title">
            <Link to="/">
              <h1>Scholars Breed</h1>
            </Link>

            <h2>Actualizing Dreams With The Scholastic Approach</h2>
          </div>
          <div className="signup">
            <div className="signup-options">
              <Link to="/auth/login" className="login-link">
                <span>Log in</span>
              </Link>

              <span style={style}>Sign up</span>
            </div>
            <SignupForm />
          </div>

          <img src={graduation} alt="" />
        </div>
      )}
    </div>
  );
};

export default SignUp;
