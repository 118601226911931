export const notificationData = [
  {
    message: "Congrats on Joining Scholars Breed",
    time: new Date(),
  },
  {
    message: "Congratulations, You have upgraded your account",
    time: new Date(),
  },
  {
    message: "Get ready to enhance your knowledge by reading our note",
    time: new Date(),
  },
];
