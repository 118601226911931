import React from 'react'
import './Home.css';
import About from './components/About/About';
import Blogs from './components/Blogs/Blogs';
import CallToAction from './components/CallToAction/CallToAction';
import Contact from './components/Contact/Contact';
import Hero from './components/Hero/Hero';
import Plans from './components/Plans/Plans';
import Programs from './components/Programs/Programs';
import Testimonials from './components/Testimonials/Testimonials'
import ScrollButton from './components/ScrollButton/ScrollButton';
import Footer from '@components/Footer/Footer';

const HomePage = () => {
  return (
    <div className="homepage">
        <Hero />
        <Programs />
        <About />
        <Blogs />
        <Plans />
        <Testimonials />
        <Contact />
        <CallToAction />
        <ScrollButton />
        <Footer />
    </div>
  )
}

export default HomePage