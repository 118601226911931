import React from "react";
import Header from "../Header/Header";
import "./Hero.css";
import heroImage from "@assets/hero-vector.png";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Link as Scroll } from "react-scroll";

const Hero = () => {
  const transition = { type: "spring", duration: 3 };
  const mobile = window.innerWidth <= 768;
  return (
    <div className="hero-wrapper" id="hero-section">
      {/* Header */}
      <Header />
      <div className="hero">
        <div className="left-h">
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ ...transition }}
            className="hero-text"
          >
            <div>
              <span className="stroke-text hero-stroke-text">Shape </span>
              <span>Your</span>
            </div>

            <div>
              <span>Ideal </span>
              <span>Mind</span>
            </div>

            <div>
              <span>Actualizing dreams with the scholastic approach</span>
            </div>
          </motion.div>

          {/*Figures*/}
          <div className="figures">
            <div>
              <span>+ 2k</span>
              <span>Students Learning</span>
            </div>

            <div>
              <span>+50</span>
              <span>Active Courses</span>
            </div>

            <div>
              <span>+20</span>
              <span>Free Courses</span>
            </div>
          </div>
          <div className="hero-buttons">
            <Link to="/auth/signup">
              <button className="btn hero-btn">Get Started</button>
            </Link>

            <Scroll to="about" span={true} smooth={true}>
              <button className="btn hero-btn">Learn More</button>
            </Scroll>
          </div>
        </div>
        <div className="right-h">
          <motion.img
            initial={{ opacity: 0, x: mobile ? -20 : 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={transition}
            src={heroImage}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
