import React from "react";
import "./ContactForm.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid} from "@fortawesome/fontawesome-svg-core/import.macro";

const ContactForm = () => {
  return (
    <div className="contact-wrapper">
      <form className="contact-form">
        <div>
          <input
            type="text"
            name="firstname"
            placeholder="First Name:"
            id=""
            required
          />
          <input
            type="text"
            name="lastname"
            placeholder="Last Name:"
            required
          />
        </div>
        <input type="email" name="email" placeholder="Email:" id="" required />
        <textarea
          name="message"
          placeholder="Your Message:"
          id=""
          cols="30"
          rows="5"
        ></textarea>
        <button
          type="submit"
          className="form-button"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          Submit
        </button>
      </form>

      <div className="contact-details">
        <div>
          <i>
            <FontAwesomeIcon icon={solid("map-marker-alt")} />
          </i>
          <span>9 Iya  Adura Street, Ajenifuja, Iyana Iyesi, Ado Odo/ Ota, Ogun State, Nigeria. </span>
        </div>

        <div>
          <i>
            <FontAwesomeIcon icon={solid('phone')} />
          </i>
          <span>+234 906 053 3005</span>
        </div>
        <div>
          <i>
            <FontAwesomeIcon icon={solid('envelope')} />
          </i>
          <span>scholarsbreed@gmail.com</span>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
