import {
    faBold,
    faItalic,
    faList,
    faListOl,
    faLink,
    faAngleRight,
    faHighlighter,
    faInfinity
  } from "@fortawesome/free-solid-svg-icons";

export const toolbarData = [
    {
        icon: faBold,
        name: "bold"
    },
    {
        icon: faItalic,
        name: "italic"
    },
    {
        icon: faList,
        name: "list"
    },
    {
        icon: faListOl,
        name: "listOl"
    },
    {
        icon: faAngleRight,
        name: "blockquote"
    },
    {
        icon:faHighlighter,
        name: "highlight"
    },

    {
        icon: faLink,
        name: "link"
    },
    {
        icon: faInfinity,
        name: "math expressions"
    },
]