import React from "react";
import "./Panel.css";
import Logo from "@assets/logo2.png";
import { UserAuth } from "../../../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const Panel = () => {
  const { user } = UserAuth();
  const navigate = useNavigate();
  let name = localStorage.getItem("user-name");

  return (
    <div className=" b-panel">
      <div className="p-container">
        <div className="p-left">
          <img src={Logo} alt="" />
        </div>
        <div className="p-right">
          {user ? (
            <div className="p-right-content">
              <button
                className="btn"
                onClick={() => navigate("/admin/dashboard")}
              >
                Dashboard
              </button>
              {user.photoUrl !== undefined ? (
                <img
                  src={user.photoUrl}
                  className="profile-image"
                  alt="profile"
                />
              ) : (
                <div className="profile-div">{name[0]}</div>
              )}
            </div>
          ) : (
            <div className="p-right-content">
              <button className="btn" onClick={() => navigate("/auth/login")}>
                Login
              </button>
              <button className="btn" onClick={() => navigate("/auth/signup")}>
                Sign Up
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Panel;
