import React from "react";
import "./CountDownTimer.css";
import { useEffect, useState } from "react";

const CountDownTimer = ({ targetTime, stopTest}) => {
  const countDownTime = new Date(targetTime).getTime();

  const [countDown, setCountDown] = useState(
    countDownTime - new Date().getTime()
  );

  useEffect(() => {
    setInterval(() => {
      setCountDown(countDownTime - new Date().getTime());
    }, 1000);
  }, []);

  const [hours, mins, secs] = getReturnValues(countDown);

  if (hours === 0 && mins === 0 && secs === 0) {
    stopTest(true);
  }

  return (
    <div>
      {hours}:{mins}:{secs}
    </div>
  );
};

const getReturnValues = (countDown) => {
  // calculate time
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [hours, minutes, seconds];
};

export default CountDownTimer;
