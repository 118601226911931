import React from 'react'
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const QuestionNumber = ({selected, questions, qNumbers, doneQuestions, skippedQuestions}) => {
  return (
    <div className="question-number">
        <div>
          <span>
            Question {selected + 1} out of {questions.length}
          </span>

          <div className="rec-arrows">
            <span
              onClick={() => {
                let slider = document.getElementById("scroll-numbers");
                slider.scrollLeft = slider.scrollLeft - 80;
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </span>

            <span
              onClick={() => {
                let slider = document.getElementById("scroll-numbers");
                slider.scrollLeft = slider.scrollLeft + 80;
              }}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </span>
          </div>
          
        </div>
        <div className="numbers" id="scroll-numbers">
          {qNumbers.map((n) => (
            <span
              key={n}
              id={`n-${n}`}
              className={
                doneQuestions.includes(n)
                  ? "circle q-done"
                  : skippedQuestions.includes(n)
                  ? "circle q-skipped"
                  : selected + 1 === n
                  ? "circle q-on"
                  : "circle"
              }
            >
              {n}
            </span>
          ))}
        </div>
      </div>
  )
}

export default QuestionNumber