import React, { useEffect, useState } from "react";
import "./Blogs.css";
import SideBar from "./components/SideBar/SideBar";
import Spinner from "../../components/Spinner/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-regular-svg-icons";
import { getAllBlogs } from "../../db";
import { useNavigate } from "react-router-dom";

// For rendering blogs to users
const Blogs = () => {
  const [loading, setLoading] = useState(false);
  const [blogData, setBlogData] = useState([]);
  const navigate = useNavigate();
  const mobile = window.innerWidth <= 768;

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      const data = await getAllBlogs();
      setBlogData(data);
      setLoading(false);
    };

    getData();
  }, []);

  return (
    <div className="blogs-page-wrapper">
      <div className="blogs-page-container">
        {loading ? (
          <Spinner />
        ) : (
          <div className="blogs-page-content">
            {blogData.map((blog, i) => (
              <div className="blogs-item" key={blog.id}>
                <div className="blogs-image">
                  <img src={blog.imageUrl} alt="" />
                </div>

                <div className="blogs-snippet">
                  <div className="blogs-item-heading">
                    <h2 onClick={() => navigate(`/blog/read/${blog.id}`)}>
                      {blog.title}
                    </h2>
                    <p>Aug 4</p>
                  </div>

                  <div className="blog-details">
                    {blog.details.slice(0, 100)}...
                  </div>

                  <div className="blogs-info">
                    <FontAwesomeIcon icon={faBookmark} />
                    <p>6 mins read</p>
                  </div>
                  <div className="blogs-item-tags">
                    {blog.tags.map((tag, i) => (
                      <div key={i} className="tag">
                        {tag}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {!mobile && <SideBar blogData={blogData} loading={loading} />}
    </div>
  );
};

export default Blogs;
