import React from "react";
import "./SubjectsPanel.css";

const Subjects = (props) => {

  return (
    <div className="subjectsPanel">
      <div className="subjects">
        <div
          className={props.index === 0 ? "subject s-active" : "subject"}
          onClick={() => props.handler(0)}
        >
          All
        </div>
        {props.subjectData.map((item, i) => (
          <div
            onClick={() => props.handler(i + 1)}
            className={props.index === i + 1 ? "subject s-active" : "subject"}
            key={i}
          >
            {item.subject}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Subjects;
