import React, { useEffect, useState } from "react";
import "./Blog.css";
import Panel from "./components/Panel/Panel";
import Spinner from "../../../components/Spinner/Spinner";
import { getBlog, updateBlog, getComments } from "../../../db";
import { useParams } from "react-router-dom";
import Preview from "../../Admin/components/Preview/Preview";
import ClapIcon from "@assets/ic_hand_clapping.png";
import CommentIcon from "@assets/ic_comment.png";
import Sidebar from "./components/Sidebar/Sidebar";
import CommentForm from "../../../Forms/CommentForm/CommentForm";
import Comment from "./components/Comment/Comment";

const Blog = () => {
  const [loading, setLoading] = useState(false);
  const [blogData, setBlogData] = useState({});
  const [commentsData, setCommentsData] = useState([]);
  const [commentLen, setCommentLen] = useState(0);
  const [liked, setLiked] = useState(false);
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    const getBlogData = async () => {
      const id = params.id;
      const blog = await getBlog(id);
      setBlogData(blog);

      const commentsData = await getComments(id);
      setCommentsData(commentsData.comments);
      setCommentLen(commentsData.comments ? commentsData.comments.length : 0);

      setLoading(false);
    };

    getBlogData();
  }, []);

  const handleLike = async () => {
    await updateBlog(blogData.id, { likes: blogData.likes + 1 }).then((res) =>
      setLiked(true)
    );
  };

  const handleUnlike = async () => {
    await updateBlog(blogData.id, { likes: blogData.likes - 1 }).then((res) =>
      setLiked(false)
    );
  };

  return (
    <div className="b-wrapper">
      <Panel />
      {loading ? (
        <Spinner />
      ) : blogData ? (
        <div className="b-container">
          {/* Left Blog Container */}
          <div className="bc-l">
            <h1>{blogData.title}</h1>
            {blogData.subtitle && <h2>{blogData.subtitle}</h2>}
            <img src={blogData.imageUrl} alt="" />
            {/* Markdown previewer for showing the blog details */}
            <Preview markdown={blogData.details} />

            {/* for showing user response to the blog */}
            <div className="b-response">
              <div onClick={liked ? handleUnlike : handleLike}>
                <img
                  src={ClapIcon}
                  alt="clap"
                  className={liked ? `liked-img` : ``}
                />
                <span>{blogData.likes}</span>
              </div>
              <div>
                <img src={CommentIcon} alt="clap" />
                <span>{commentLen}</span>
              </div>
            </div>

            <div className="blogs-item-tags">
              {blogData.tags &&
                blogData.tags.map((tag, i) => (
                  <div key={i} className="tag">
                    {tag}
                  </div>
                ))}
            </div>

            <div className="comment-section" id="comment-section">
              <h2>Comments...</h2>
              <CommentForm id={params.id} />
              <div className="comments">
                {commentsData &&
                  commentsData.map((comment, index) => (
                    <Comment data={comment} key={index} />
                  ))}
              </div>
            </div>
          </div>

          {/* Right Blog Container */}
          <div className="bc-r b-sticky">
            {!loading && blogData && (
              <Sidebar tags={blogData.tags && blogData.tags} id={params.id} />
            )}
          </div>
        </div>
      ) : (
        <div>Not Found</div>
      )}
    </div>
  );
};

export default Blog;
