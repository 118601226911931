import React from "react";
import { useState } from "react";
import { UserAuth } from "../../contexts/AuthContext";
import { sendComment } from "../../db";

const CommentForm = ({ id }) => {
  const { user } = UserAuth();
  let name = localStorage.getItem("user-name");
  const [comment, setComment] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    let url = user.photoUrl ? user.photoUrl : ""
    let data = { username: name, imageUrl: url, comment };
    let res = await sendComment(id, data);
    console.log(res);
    setComment("");
  };

  return (
    <form className="contact-form comment-form" onSubmit={handleSubmit}>
      {user ?
      <>
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* Render User profile image incase user has added it, else it returns a div having the first letter of the name */}
        {user.photoUrl ? (
          <img src={user.photoUrl} alt="profile" className="profile-image" />
        ) : (
          <div className="profile-div">{name[0]}</div>
        )}
        <div>{name}</div>
      </div>

      <textarea
        name=""
        id=""
        cols="30"
        rows="10"
        placeholder="What are your thoughts?"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      ></textarea>
      <button className="form-button" onClick={handleSubmit}>
        Respond
      </button>
      </>
      :
      <div>You need to sign in to comment</div>
    }
    </form>
  );
};

export default CommentForm;
