import React from "react";
import "./Sidebar.css";
import profileImage from "@assets/profile-image.png";
import { UilSignOutAlt } from "@iconscout/react-unicons";
import { useState } from "react";
import { UserAuth } from "../../../../contexts/AuthContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SideBarData } from "@data/adminSidebarData";

const Sidebar = (props) => {
  const [selected, setSelected] = useState("");
  const navigate = useNavigate();

  const { user, logout } = UserAuth();

  useEffect(() => {
    setSelected(props.id);
  }, [props.id]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="sidebar" id="sidebar">
      <div className="sticky">
        <div className="user-info">
          <img src={profileImage} alt="" />
          <div className="name">{user && user.displayName}</div>
          <div>Admin</div>
          <a href="/profile/edit" style={{ color: "white" }}>
            edit profile
          </a>
        </div>
        <div className="menu">
          {SideBarData.map((item, idx) => (
            <div
              className={selected === idx ? "menu-item active" : "menu-item"}
              key={idx}
              onClick={() => {
                navigate(item.link);
                setSelected(idx);
              }}
            >
              <div>
                <item.icon />
              </div>
              <div className="dash-nav-title">{item.heading}</div>
            </div>
          ))}
          <div className="menu-item" onClick={handleLogout}>
            <div>
              <UilSignOutAlt />
            </div>
            <div className="dash-nav-title">Logout</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
