import React, { useState } from "react";
import "./Notifications.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { notificationData } from "@data/notificationData";
import moment from "moment/moment";

const Notifications = () => {
  const [opened, setOpened] = useState(false);
  let n = notificationData.length;
  return (
    <div className="notification-container">
      <div className="notifications" onClick={() => setOpened(!opened)}>
        <div className="notif-amount">{n}</div>
        <FontAwesomeIcon icon={faBell} className="notification" />
      </div>
      {opened === true ? (
        <div className="notif-message">
          {notificationData.map((notification, i) => (
            <div className="notif" key={i}>
              <span>{notification.message}</span>
              <span>
                {moment(notification.time).fromNow()}
              </span>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default Notifications;
