import React from "react";
import "./NewsRem.css";
import Icon from "@assets/dash-blog.svg";
import { useNavigate } from "react-router-dom";

const NewsRem = () => {
  const navigate = useNavigate()

  return (
    <div className="news-reminder dash-cards card">
      <div>
        <img src={Icon} alt="" />
        <span>Checkout our blogs</span>
        <span>to keep</span>
        <span>yourself updated</span>
      </div>

      <div className="card_button" onClick={() => navigate("/blogs")} >Check Latest News</div>
    </div>
  );
};

export default NewsRem;
