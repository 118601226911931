import React from "react";
import ContactForm from "@forms/ContactForm/ContactForm";
import "./Contact.css";
import contactIllustration from "@assets/contact-il.png";

const Contact = () => {
  return (
    <div className="contact" id="Contact">
      <div
       className="left-c">
        <img src={contactIllustration} alt="" />
      </div>

      <div className="right-c">
        <div className="contact-header">
          <span>Contact</span> <span className="stroke-text">Us</span>
        </div>
        <ContactForm />
      </div>
    </div>
  );
};

export default Contact;
