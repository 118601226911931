import React from "react";
import "./About.css";
import aboutImage from "@assets/about-il.png";
import { useNavigate } from "react-router-dom";

const About = () => {
  const transition = {type:'spring', duration: 3}
  const mobile = window.innerWidth<=400
  const navigate = useNavigate()

  return (
      <div className="About" id="about">
        <div 
        className="about-l">
          <div>
            <span className="stroke-text">Who</span>
            <span>We</span>
            <span className="stroke-text">Are</span>
          </div>

          <div>
          <p>Scholars Breed Academy Limited(founded since 3rd March, 2021), a sole proprietorship is an online tutorial with global recognition and reputation. We deal in WASSCE, UTME and post-UTME in order to help admission seekers into the their preferred courses and institutions.</p>

          <p>Over the years, since the inception of this company,  with much critical acclaim, our philosophy has remained true to its roots by providing a truly distinct tutorial that provides the best educational service which in the long run did help and is still helping students in actualizing dreams through scholastic approach.</p>

          <p>Our mission is to provide explicit, concise, simplified, detailed and easy-to-learn tutorial for students to get the best, beat the best and be the best, setting the pace among the best of their equals. We also strive to uphold the priceless value and excellence of education by providing inclusive, competent and professional supports to students to aid their success in their examinations.</p>
          </div>

          <button className="btn" onClick={() => navigate("/about") }>Learn More</button>
        </div>

        <div
         initial={mobile? {opacity:0}: {opacity:0, x:100}}
         whileInView = {mobile? {opacity: 1}: {opacity:1, x:0}}
         transition={transition}
        className="about-r">
          <img src={aboutImage} alt="" />
        </div>
      </div>
  );
};

export default About;
