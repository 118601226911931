import React from 'react'
import './Reminder.css'
import test from '@assets/test.svg'
import { useNavigate } from 'react-router-dom'

const Reminder = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/user/tests')
  }
  return (
    <div className="reminder dash-cards card">
        <div>
            <img src={test} alt="" />
            <span>Invest in yourself</span>
            <span>By Taking a Test</span>
            <span>Today!</span>
        </div>

        <div className='card_button' onClick={handleClick}>
            Take a Test
        </div>
    </div>
  )
}

export default Reminder