export const subjects = [
    {
        subject: "English",
        value: "english"
    },
    {
        subject: "Mathematics",
        value: "mathematics"
    },
    {
        subject: "Physics",
        value: "physics"
    },
    {
        subject: "Chemistry",
        value: "chemistry"
    },
    {
        subject: "Biology",
        value: "biology"
    },
    {
        subject: "Accounting",
        value: "accounting"
    },
    {
        subject: "Economics",
        value: "economics"
    },
    {
        subject: "Government",
        value: "government"
    },
    {
        subject: "Lit. In English",
        value: "englishlit "
    },
    {
        subject: "CRS",
        value: "crk"
    },
    {
        subject: "IRK",
        value: "irk"
    },

]