import React from "react";

const Questions = ({ question, selected, i, handleChange }) => {
  return (
    <div key={question.id} className={selected === i ? "questions" : "q-none"}>
      <div className="section-question">
        {question.image && <img src={question.image} alt="" />}
        {question.section && <div className="section">{question.section}</div>}
        <div
          className="test-question"
          dangerouslySetInnerHTML={{ __html: question.question }}
        ></div>
      </div>

      <form className="option-form">
        <label className="option">
          <input
            type="radio"
            name={`${question.id}`}
            value={`a`}
            onChange={(e) => {
              handleChange(e, i);
            }}
          />
          {question.option.a}
        </label>

        <label className="option">
          <input
            type="radio"
            name={`${question.id}`}
            value={`b`}
            onChange={(e) => {
              handleChange(e, i);
            }}
          />
          {question.option.b}
        </label>

        <label className="option">
          <input
            type="radio"
            name={`${question.id}`}
            value={`c`}
            onChange={(e) => {
              handleChange(e, i);
            }}
          />
          {question.option.c}
        </label>

        <label className="option">
          <input
            type="radio"
            name={`${question.id}`}
            value={`d`}
            onChange={(e) => {
              handleChange(e, i);
            }}
          />
          {question.option.d}
        </label>
      </form>
    </div>
  );
};

export default Questions;
