import {
    UilEstate,
    UilCalculatorAlt,
    UilNotes,
    UilBookOpen, 
} from '@iconscout/react-unicons'

export const SideBarData = [
    {
        icon: UilEstate,
        heading: "Dashboard",
        link: "/admin/dashboard"
    },
    {
        icon: UilBookOpen,
        heading: "Notes",
        link: "/admin/notes"
    },
    {
        icon: UilCalculatorAlt,
        heading: "Tests",
        link: "/admin/tests"
    },
    {
        icon: UilNotes,
        heading: "Blogs",
        link: "/admin/blogs"
    },

]