import React, { useState } from "react";
import "./SignupForm.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { UserAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const SignupForm = () => {
  const iconStyle = { color: "rgba(0,0,0,0.5)" };
  const { createUser } = UserAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmPassword, setConfirmPassword]  = useState("")
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    setError("");
    const confirm = confirmPassword === password

    if (confirm) {
      try {
        await createUser(email, password, firstName, lastName)
        setLoading(false)
        navigate("/auth/login");
      } catch (error) {
        setLoading(false)
        setError(error.message);
      }
    } else {
      setLoading(false)
      setError("passwords don't match")
    }
  };
  return (
    <form action="" className="signup-form" onSubmit={handleSubmit}>
      <div
        className="form-error"
        style={error ? { display: "block" } : { display: "none" }}
      >
        {error}
      </div>
      <div className="form-item">
        <FontAwesomeIcon style={iconStyle} icon={regular("user")} />
        <input
          type="text"
          placeholder="First Name"
          onChange={(e) => setFirstName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Last Name"
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>

      <div className="form-item">
        <FontAwesomeIcon style={iconStyle} icon={regular("envelope")} />
        <input
          type="email"
          name="email"
          id="register-email"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className="form-item">
        <FontAwesomeIcon style={iconStyle} icon={solid("lock")} />
        <input
          type="password"
          name="password"
          id="register-password"
          placeholder="Your Password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <div className="form-item">
        <FontAwesomeIcon style={iconStyle} icon={solid("lock")} />
        <input
          type="password"
          name="confirm-password"
          id="confirm-password"
          placeholder="Confirm Password"
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </div>
      <p>By signing up, you're agreeing with our terms & conditions</p>
      <button type="submit" className="signup-btn">
      {loading ? (
          <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
        ) : (
          "Create Account"
        )}
      </button>
     
    </form>
  );
};

export default SignupForm;
