import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./Overview.css";

const Overview = ({score, streak, notesRead}) => {
  return (
    <div className="overview card">
      <div>Overview</div>
      <div className="overview-items">
        <div className="scores overview-section">
          <span className="overview-title">Highest Score</span>

          <div
            className="progress"
            style={{ width: 100, height: 100, color: "#6F2DBD" }}
          >
            <CircularProgressbar
              value={score}
              text={`${score}%`}
              styles={buildStyles({
                pathColor: "#64C3E8",
                textColor: "#4A154B",
              })}
            />
          </div>
        </div>

        <div className="streak overview-section">
          <div className="overview-title">Current Streak</div>
          <div>🔥</div>
          <div className="streak-value">{streak}</div>
        </div>

        <div className="courses-taken overview-section">
          <div className="overview-title">Notes Read</div>
          <div>📖</div>
          <div>{notesRead}</div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
