import React from "react";
import LoginForm from "@forms/LoginForm/LoginForm";
import "./Login.css";
import { Link } from "react-router-dom";
import graduation from "@assets/graduation.jpg";
import { useState, useEffect } from "react";
import Spinner from "../../../components/Spinner/Spinner";


const Login = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(()=> {
      setLoading(false)
    }, 500);
  }, []);

  const style = {
    backgroundColor: "white",
    zIndex: 2,
  };

  return (
    <div className="loading-container">
      {
        loading ?
         <Spinner />
        :

        <div className="login-container">

          <div className="title">
            <Link to="/" >
              <h1>Scholars Breed</h1>
            </Link>
    
            <h2>Actualizing Dreams With The Scholastic Approach</h2>
          </div>
    
          <div className="login">
            <div className="options">
              <span style={style}>Log in</span>
              <Link className="signup-link" to="/auth/signup">
                <span>Sign up</span>
              </Link>
            </div>
            <LoginForm />
          </div>
    
          <img src={graduation} alt="" />
        </div>
      }
      </div>
  );
};

export default Login;
