import React, { useState } from "react";
import "./Header.css";
import Logo from "@assets/logo.png";
import Bars from "@assets/bars.png";
import {useNavigate} from "react-router-dom"
import { Link as Scroll } from "react-scroll";

const Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpened, setMenuOpened] = useState(false);
  const navigate = useNavigate()

  return (
    <div className="header">
      <img src={Logo} alt="" className="logo" />

      {menuOpened === false && mobile === true ? (
        <div
          style={{
            backgroundColor: "var(--secondaryColor)",
            border: "1px solid white",
            borderRadius: "5px",
            padding: "0.5rem",
            position: "fixed",
            right: "2rem",
            zIndex: 99,
          }}
          onClick={() => {
            setMenuOpened(true);
          }}
        >
          <img
            src={Bars}
            alt=""
            style={{ width: "1.5rem", height: "1.5rem" }}
          />
        </div>
      ) : (
        <div onClick={() => setMenuOpened(false)} className="dropdown">
          <ul className="header-menu">
            <li>
              <Scroll
                onClick={() => setMenuOpened(false)}
                to="hero-section"
                span={true}
                smooth={true}
              >
                Home
              </Scroll>
            </li>
            <li>
              <Scroll
                onClick={() => setMenuOpened(false)}
                to="about"
                span={true}
                smooth={true}
              >
                About
              </Scroll>
            </li>
            <li>
              <Scroll
                onClick={() => setMenuOpened(false)}
                to="Blogs"
                span={true}
                smooth={true}
              >
                Blogs
              </Scroll>
            </li>
            <li>
              <Scroll
                onClick={() => setMenuOpened(false)}
                to="plans-section"
                span={true}
                smooth={true}
              >
                Plans
              </Scroll>
            </li>
            <li>
              <Scroll
                onClick={() => setMenuOpened(false)}
                to="testimonials"
                span={true}
                smooth={true}
              >
                Testimonials
              </Scroll>
            </li>
            <li>
              <Scroll
                onClick={() => setMenuOpened(false)}
                to="Contact"
                span={true}
                smooth={true}
              >
                Contact Us
              </Scroll>
            </li>
          </ul>
          <div className="header-btn">
            <button onClick={() => navigate("/auth/login")} className="btn nav-btn">Login</button>
            <button onClick={() => navigate("/auth/signup")} className="btn nav-btn">Signup</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
