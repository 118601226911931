import React from "react";
import './ShowScores.css'
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ShowScores = () => {
    const location = useLocation()
    const score = location.state.score
    const navigate = useNavigate();

  return (
    <div className="show-scores">
      <div className="scores card">
        <span>Congratulations on completing your test</span>
        <span>Your Score: </span> <span>{score}%</span>
        <button className="btn" onClick={() => navigate('/user/dashboard')} >Continue</button>
      </div>
    </div>
  );
};

export default ShowScores;
