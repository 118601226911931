import React from "react";
import "./ViewQuestion.css";

const ViewQuestion = ({ question, num }) => {
  return (
    <div className="view-question">
      <div className="info">
        <div className="num">{num}</div>
        <button className="btn">Edit</button>
      </div>
      {question.image && (
        <div className="question-image">
          <img src={question.image} alt="" />
        </div>
      )}
      {question.section && (
        <div className="question-section">Section: {question.section}</div>
      )}
      <div className="question-question">
        <span>Question:</span> <span>{question.question}</span>
      </div>
      <div className="question-options">
        <div>Options:</div>
        <div>
          <b>a: </b> {question.option.a}
        </div>
        <div>
          <b>b: </b>
          {question.option.b}
        </div>
        <div>
          <b>c: </b> {question.option.c}
        </div>
        <div>
          <b>d: </b>
          {question.option.d}
        </div>
      </div>
      <div className="question-answer">
        <span>Answer:</span> <span>{question.answer}</span>
      </div>
    </div>
  );
};

export default ViewQuestion;
