import React from 'react'
import CountDownTimer from "../../CountDownTimer/CountDownTimer";

const TestPanel = ({mode, subject, targetTime, setEndTest}) => {
  return (
    <div className={mode==="jamb"? "test-panel panel":"test-panel"}>
        <div style={{textTransform: 'uppercase'}}>{subject}</div>
        <div>
          <div className="time">
            <CountDownTimer targetTime={targetTime} stopTest={setEndTest} />
          </div>
          <div className="time-left">Time Left</div>
        </div>
      </div>
  )
}

export default TestPanel