import React from "react";
import "./Notes.css";
import Panel from "../components/Panel/Panel";
import SideBar from "../components/SideBar/SideBar";
import SubjectsPanel from "../components/SubjectsPanel/SubjectsPanel";
import Spinner from "@components/Spinner/Spinner";
import Footer from "@components/Footer/Footer";
import { useState, useEffect } from "react";
import { SubjectsData } from "@data/SubjectsData";
import { NotesData } from "@data/NotesData";
import Note from "./Note/Note";
import noteIcon from "@assets/availableNotes.svg";

const Notes = () => {
  const [loading, setLoading] = useState(false);
  const [subjectIndex, setSubjectIndex] = useState(1);
  const [notes, setNotes] = useState([]);

  const filterNotes = (subject) => {
    const filteredNotes = NotesData.filter((note) => note.subject === subject);
    setNotes(filteredNotes);
  };

  useEffect(() => {
    filterNotes(SubjectsData[subjectIndex - 1].subject);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const subjectHandler = (idx) => {
    setSubjectIndex(idx);
    if (idx === 0) {
      setNotes(NotesData);
    } else {
      filterNotes(SubjectsData[idx - 1].subject);
    }
  };

  return (
    <div className="wrapper">
      <div className="page-wrapper">
        <SideBar id={1} />
        <div className="container">
          <Panel />
          {loading ? (
            <Spinner />
          ) : (
            <div className="page-content">
              <div className="overlay" id="overlay"></div>
              <SubjectsPanel
                subjectData={SubjectsData}
                index={subjectIndex}
                handler={subjectHandler}
              />
              <div className="note-container">
                <div className="dash-nav-heading">
                  <img src={noteIcon} alt="note-icon" />
                  <h1>Available Notes</h1>
                </div>
                <div className="notes">
                  {notes.map((note, i) => (
                    <Note note={note} key={i} />
                  ))}
                </div>
              </div>
              <Footer />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notes;
