import React from 'react'
import './PageNotFound.css'
import NotFoundImage from "@assets/not_found.svg"
import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
    <div className="not-found">
      <img src={NotFoundImage} alt="" />
      <h1>Page Not Found :(</h1>

      <Link to="/">
      <button className='btn'>
      &larr; Back to site
      </button>
      </Link>
    </div>
  )
}

export default PageNotFound