import moment from "moment/moment";
import "./Comment.css";

const Comment = ({ data }) => {
  return (
    <>
      {data && (
        <div className="card comment-card">
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            {/* Render User profile image incase user has added it, else it returns a div having the first letter of the name */}
            {data.imageUrl !== "" ? (
              <img
                src={data.imageUrl}
                alt="profile"
                className="profile-image"
              />
            ) : (
              <div className="profile-div">{"K"}</div>
            )}
            <div className="comment-name">
              <div>{data.username}</div>
              <div>{moment(data.createdAt).fromNow()}</div>
            </div>
          </div>

          <div>{data.comment}</div>
        </div>
      )}
    </>
  );
};

export default Comment;
