import React, { useState } from "react";
import "./CreateTestForm.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { setDailyQuestions, uploadImage } from "../../db";
import FileBase from "react-file-base64";

const CreateTestForm = ({ subject, showNotification }) => {
  const [addImage, setAddImage] = useState(false);
  const initialData = {
    imageUrl: "",
    section: "",
    question: "",
    option: {
      a: "",
      b: "",
      c: "",
      d: ""
    },
    answer: "",
    point: 2
  }
  const [testData, setTestData] = useState({
    ...initialData
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let notification = await setDailyQuestions(subject, testData)
    showNotification(notification)
    setTestData({...initialData})
  };

  const setImage = async (file) => {
    const url = await uploadImage(file)
    setTestData({...testData, imageUrl: url})
  }

  return (
    <form className="test-form" onSubmit={handleSubmit}>
      <div className="add-image">
        <div>
          <button className="btn" onClick={() => setAddImage(!addImage)} >
          <FontAwesomeIcon icon={faImage} />
          <span>{addImage ? "Close": "Add Image"}</span>
          </button>
          <br />
          {addImage && <FileBase
            type="file"
            multiple={false}
            onDone={(file) => setImage(file)}
          />}
        </div>
      </div>
      <input
        type="text"
        value={testData.section}
        onChange={(e) => setTestData({...testData, section: e.target.value})}
        name="section"
        placeholder="Enter section (optional)"
      />
      <textarea
        value={testData.question}
        onChange={(e) => setTestData({...testData, question: e.target.value})}
        placeholder="Enter a Question"
        required
      ></textarea>
      <div className="form-options">
        <input
          type="text"
          value={testData.option.a}
          placeholder="Enter option A"
          onChange={(e) => setTestData({...testData, option: {...testData.option, a: e.target.value}})}
          required
        />
        <input
          type="text"
          value={testData.option.b}
          placeholder="Enter option B"
          onChange={(e) => setTestData({...testData, option: {...testData.option, b: e.target.value}})}
          required
        />
        <input
          type="text"
          value={testData.option.c}
          placeholder="Enter option C"
          onChange={(e) => setTestData({...testData, option: {...testData.option, c: e.target.value}})}
          required
        />
        <input
          type="text"
          value={testData.option.d}
          placeholder="Enter option D"
          onChange={(e) => setTestData({...testData, option: {...testData.option, d: e.target.value}})}
          required
        />
      </div>
      <div className="form-answer">
        <input
          type="text"
          value={testData.answer}
          placeholder="Enter answer: a/b/c/d"
          onChange={(e) => setTestData({...testData, answer: e.target.value})}
        />
      </div>

      <button type="submit" className="btn submitbtn">
        Create Test
      </button>
    </form>
  );
};

export default CreateTestForm;
